import { _LINEAR_PARAMS, _NPVR_PARAMS, _VOD_PARAMS } from './send_event_context_base';
import { _MSG_LINEAR, _MSG_NPVR, _MSG_VOD } from './send_event_names';

// LOGICA PARA OBTENER EL NOMBRE DE EVENTO CORRECTO DEPENDIENDO DEL TIPO DE ASSET
function getMsgEventFor(eventName, asset) {
	if (asset.type) {
		//VOD SERIES O MOVIE
		switch (asset.type) {
		case 'SERIE':
		case 'MOVIE':
		case 'CHAPTER':
			return getVodEventName(eventName);
		case 'RECORD':
			return getNpvrEventName(eventName);
		case 'LIVE':
			return getLiveEventName(eventName);
		default:
			break;
		}
	} else {
		switch (asset.status) {
		// CATCHUP O LIVE
		case 'PRESENTE':
			return getLiveEventName(eventName);
		case 'PASADO':
			return getVodEventName(eventName);
		case 'Recorded':
			return getNpvrEventName(eventName);
		default:
			break;
		}
	}
}

function getLiveEventName(eventName) {
	switch (eventName) {
	case 'LOAD':
		return _MSG_LINEAR.LINEAR_LOAD;
	case 'START':
		return _MSG_LINEAR.LINEAR_START;
	case 'PAUSE':
	case 'STOV':
	case 'FASTFORWARD':
	case 'TIMESHIFT':
		return _MSG_LINEAR.LINEAR_ENABLE_TIMESHIFT;
	case 'POSITION':
		return _MSG_LINEAR.LINEAR_POSITION;
	case 'STOP':
		return _MSG_LINEAR.LINEAR_STOP;
	case 'RESUME':
		return _MSG_LINEAR.LINEAR_RESUME;
	case 'LOAD_ERROR':
		return _MSG_LINEAR.LINEAR_INITIAL_LOAD_ERROR;
	case 'NON_FATAL_ERROR':
		return _MSG_LINEAR.LINEAR_PLAYBACK_NON_FATAL_ERROR;
	case 'FATAL_ERROR':
		return _MSG_LINEAR.LINEAR_PLAYBACK_FATAL_ERROR;
	case 'CONTEXT_ERROR':
		return _MSG_LINEAR.LINEAR_STOP_ON_CONTEXT_CHANGE;
	case 'DISPLAY_MODE_CHANGE':
		return _MSG_LINEAR.LINEAR_DISPLAY_MODE_CHANGE;
	case 'STREAM_CHANGE':
		return _MSG_LINEAR.LINEAR_STREAM_CHANGE;
	default:
		break;
	}
}


function getNpvrEventName(eventName) {
	switch (eventName) {
	case 'LOAD':
		return _MSG_NPVR.NPVR_LOAD;
	case 'START':
		return _MSG_NPVR.NPVR_START;
	case 'POSITION':
		return _MSG_NPVR.NPVR_POSITION;
	case 'COMPLETE':
		return _MSG_NPVR.NPVR_STOP;
	case 'PAUSE':
		return _MSG_NPVR.NPVR_PAUSE;
	case 'STOP':
		return _MSG_NPVR.NPVR_STOP;
	case 'RESUME':
		return _MSG_NPVR.NPVR_RESUME;
	case 'LOAD_ERROR':
		return _MSG_NPVR.NPVR_INITIAL_LOAD_ERROR;
	case 'NON_FATAL_ERROR':
		return _MSG_NPVR.NPVR_PLAYBACK_NON_FATAL_ERROR;
	case 'FATAL_ERROR':
		return _MSG_NPVR.NPVR_PLAYBACK_FATAL_ERROR;
	case 'CONTEXT_ERROR':
		return _MSG_NPVR.NPVR_STOP_ON_CONTEXT_CHANGE;
	case 'DISPLAY_MODE_CHANGE':
		return _MSG_NPVR.NPVR_DISPLAY_MODE_CHANGE;
	default:
		break;
	}
}
	
function getVodEventName(eventName) {
	switch (eventName) {
	case 'LOAD':
		return _MSG_VOD.VOD_LOAD;
	case 'START':
		return _MSG_VOD.VOD_START;
	case 'POSITION':
		return _MSG_VOD.VOD_POSITION;
	case 'COMPLETED':
		return _MSG_VOD.VOD_COMPLETE;
	case 'PAUSE':
		return _MSG_VOD.VOD_PAUSE;
	case 'STOP':
		return _MSG_VOD.VOD_STOP;
	case 'RESUME':
		return _MSG_VOD.VOD_RESUME;
	case 'PLAYBACKERROR': 
		return _MSG_VOD.VOD_PLAYBACK_NON_FATAL_ERROR;
	case 'LOAD_ERROR':
		return _MSG_VOD.VOD_INITIAL_LOAD_ERROR;
	case 'NON_FATAL_ERROR':
		return _MSG_VOD.VOD_PLAYBACK_NON_FATAL_ERROR;
	case 'FATAL_ERROR':
		return _MSG_VOD.VOD_PLAYBACK_FATAL_ERROR;
	case 'CONTEXT_ERROR':
		return _MSG_VOD.VOD_STOP_ON_CONTEXT_CHANGE;
	case 'DISPLAY_MODE_CHANGE':
		return _MSG_VOD.VOD_DISPLAY_MODE_CHANGE;
	default:
		break;
	}
}

// LOGICA PARA OBTENER EL CONTEXTO A ENVIAR CON EL REPORTE
function getEventContextFor(eventName, asset, seconds) {
	if (asset.type) {
		//VOD SERIES O MOVIE
		switch (asset.type) {
		case 'SERIE':
		case 'MOVIE':
		case 'CHAPTER':
			return getVodContext(seconds);
		case 'RECORD':
			return getNpvrContext(eventName, seconds);
		case 'LIVE':
			return getLiveContext(eventName, seconds);
		default:
			break;
		}
	} else {
		switch (asset.status) {
		// CATCHUP O LIVE
		case 'PRESENTE':
			return getLiveContext(eventName, seconds);
		case 'PASADO':
			return getVodContext(seconds);
		case 'Recorded':
			return getNpvrContext(eventName, seconds);
		default:
			break;
		}
	}
}

function getLiveContext(eventName, seconds) {
	let liveContext = _LINEAR_PARAMS;
	switch (eventName) {
	case 'LOAD':
		liveContext.throughNanoCdn = false;
		liveContext.frequency = false;
		return liveContext;
	case 'START':
		liveContext.audioLanguage = 'spa';
		liveContext.videoCodec = null;
		liveContext.technology = 2; //HLS
		liveContext.drm = 1; //VERIMATRIX
		return liveContext;
	case 'PAUSE':
	case 'STOV':
	case 'FASTFORWARD':
	//case 'TIMESHIFT': // USE FOR PAUSE STOVE & FF
		liveContext.reason = timeShiftReason(eventName); //1 pause - 2 FF - 3 stov
		return liveContext;
	case 'POSITION':
		liveContext.livePosition = seconds.livePosition;
		liveContext.bufferStartPosition = seconds.bufferStartPosition;
		liveContext.playbackPosition = seconds.playbackPosition;
		

		return liveContext;
		// window.vip.player.getTimeInfo().then((res)=>{
			
		// });
		break;
	case 'STOP':
		return liveContext;
	case 'RESUME':
		return liveContext;
	case 'LOAD_ERROR':
		return liveContext;
	case 'NON_FATAL_ERROR':
		return liveContext;
	case 'FATAL_ERROR':
		return liveContext;
	case 'CONTEXT_ERROR':
		return liveContext;
	case 'DISPLAY_MODE_CHANGE':
		return liveContext;
	case 'STREAM_CHANGE':
		return liveContext;
	default:
		break;
	}
}

function getVodContext(seconds) {
	const context = _VOD_PARAMS;
	const status = window.vip.player.getStats();
	context.bitrate = status
	context.position = seconds;
	context.timeSpent = parseInt(status?.playTime);
	context.bitrate = status?.streamBandwidth;
	context.width = status?.width;
	context.height = status?.height;
	return context;
}

function getNpvrContext(eventName, seconds) {
	const context = _NPVR_PARAMS;
	switch (eventName) {
		case 'LOAD':
			return context;
		case 'START':
			return context;
		case 'PAUSE':
		case 'STOV':
		case 'FASTFORWARD':
			return context;
		case 'POSITION':
			context.position = seconds.livePosition;
			return context;
			break;
		case 'STOP':
			return context;
		case 'RESUME':
			return context;
		case 'LOAD_ERROR':
			return context;
		case 'NON_FATAL_ERROR':
			return context;
		case 'FATAL_ERROR':
			return context;
		case 'CONTEXT_ERROR':
			return context;
		case 'DISPLAY_MODE_CHANGE':
			return context;
		case 'STREAM_CHANGE':
			return context;
		default:
			break;
		}
}


// HELPERS

function timeShiftReason(action) {
	switch (action) {
	case 'PAUSE':
		return 1;
	case 'FASTFORWARD':
		return 2;
	case 'STOV':
		return 3;
	default:
		break;
	}
}

export {
	getMsgEventFor,
	getEventContextFor
};