/**
 * Parámetros comúnes
*/
const privateTabId = 'tplay.privateTabId';
const publicTabId = 'tplay.publicTabId';
const broadcastChannel = 'tplay_sc';
const broadcastMessage = 'stop_now';

/**
 * Genera un tabId único.
 * Genera un tabId único para la pantalla actual y lo guarda en el sessionStorage.
 * @returns uuid
 */
const setPrivateTabId = () => {
    let tabId = telecentro.tplay.core.utiles.uuid();
    window.sessionStorage.setItem(privateTabId, tabId);
    return tabId;
}

/**
 * Retorna un tabId único.
 * Si no existe, genera un tabId único para la pantalla actual y lo guarda en el sessionStorage.
 * Si ya existe, se mantiene el actual.
 * @returns uuid
 */
const getPrivateTabId = () => {   
    let tabId = window.sessionStorage.getItem(privateTabId);
    if(!tabId){
        tabId = setPrivateTabId();
    }
    return tabId;
}

/**
 * Retorna un tabId único.
 * Si no existe, genera un tabId único para la pantalla actual y lo guarda en el sessionStorage.
 * Si ya existe, se mantiene el actual.
 * @returns uuid
 */
const getPublicTabId = () => {   
    let tabId = telecentro.tplay.core.localStorageFake.getItem(publicTabId);
    if(!tabId){
        tabId = getPrivateTabId();
        telecentro.tplay.core.localStorageFake.setItem(publicTabId, tabId);
    }
    return tabId;
}

/**
 * Controla si el tab actual tiene permisos de reproduccion
 */
const canPlayback = () => {
    const privateTabId = getPrivateTabId();
    const publicTabId = getPublicTabId();
    if(privateTabId === publicTabId){
        return true;
    } else {
        return false;
    }
}

/**
 * Toma el control de la reproducción, si no lo tenía; 
 * Si ya tenía el control de la reproducción, continúa normalmente.
 * 
 */
const takeControl = () => {
    const tabId = getPrivateTabId();
    telecentro.tplay.core.localStorageFake.setItem(publicTabId, tabId);
    return tabId;
}

const getBroadcastChannel = () => {
    return broadcastChannel;
}

const getBroadcastMessage = () => {
    return broadcastMessage;
}

/**
 * Inicializa los uuid públicos y privados
 * Se suscribe al canal de broadcast para escuchar 
 */
const init = () => {
    setPrivateTabId();
}


export { init, canPlayback, takeControl, getBroadcastChannel, getBroadcastMessage };
