<template>
  <div
    id="player-container"
    class="player player-hide"
    data-fullscreen="false"
    :class="{ 'hidde-layer-on-pin': hiddePlayerOnPin }"
  >
    <div
      class="player-wrapper"
      :class="playerType"
      v-on:click="onPlayerClick()"
      v-on:dblclick="onPlayerDoubleClick()"
    >
      <Ccast
        v-if="isCastAvailable()"
        v-show="isCcast"
        :isCCast="isCcast"
        :poster="getPoster()"
        :title="nTitle"
        :subtitle="timeText"
      />

      <div id="video" :class="{ isCasting: isCcast }" preload="none"></div>

      <div class="player-controls">
        <a
          v-on:click.stop="onControlClose()"
          v-on:dblclick.stop=""
          href="#"
          class="btn btn-exit"
          id="btn-exit"
          title="Salir del reproductor"
        >
          <i class="zmdi zmdi-long-arrow-left"></i>
        </a>

        <img
          src="../assets/images/t-play_logo.svg"
          width="120"
          height="40"
          alt="T-Play Logo"
          class="player-logo"
        />

        <div
          class="float-right mode-controls"
          v-on:click.stop=""
          v-on:dblclick.stop=""
        >
          <google-cast-launcher
            v-if="isCastAvailable()"
            id="castbutton"
            @click="setIsPlayer()"
            clickable="true"
          ></google-cast-launcher>

          <a
            href="#"
            class="btn btn-pip"
            title="Hacer flotante (PIP)"
            v-on:dblclick.stop=""
          >
            <i class="zmdi zmdi-picture-in-picture" v-on:dblclick.stop=""></i>
            <i class="zmdi zmdi-fullscreen-alt" v-on:dblclick.stop=""></i>
          </a>

          <a href="#" class="btn btn-fullscreen" title="Ir a pantalla completa">
            <i class="zmdi zmdi-fullscreen"></i>
          </a>
        </div>

        <div
          class="channel-number"
          v-if="asset.channel && asset.channel.lcn && !isCcast"
        >
          {{ asset.channel.lcn }}
        </div>
      </div>

      <div
        class="player-controls-bottom"
        :class="[isTvShow ? 'content-tv' : 'content-vod']"
      >
        <div
          v-if="!isCcast"
          class="video-caption"
          :class="{ 'video-caption--tv': hasChannelLogo }"
        >
          <div class="channel-logo" v-if="hasChannelLogo">
            <img :src="asset.channel.logo" width="200" height="150" />
          </div>

          <span
            class="video-title"
            >{{ nTitle }}</span
          >

          <template v-if="asset.type == 'SERIE' || asset.type == 'CHAPTER'">
            <span class="video-subtitle season"
              >{{ temporadaEpisodio }} <span>{{ asset.title }}</span></span
            >
          </template>
          <template v-else-if="asset.type == 'MOVIE'">
            <span class="video-subtitle video-year">{{ asset.year }}</span>
          </template>

          <template v-if="asset.channel && asset.startHour && asset.endHour">
            <span class="video-subtitle season">{{ asset.channel.name }}</span>
            <span class="video-subtitle chapter"
              >{{ asset.channel.name && timeText.length > 0 ? "- " : ""
              }}{{ timeText }}</span
            >
          </template>

          <span v-if="isLive && !asset.isFakeProgram" class="live-indicator">
            <i class="zmdi zmdi-circle"></i>
            <span class="pulse"></span>
            en vivo
          </span>

          <span v-if="asset.certificateCode" class="certificate-code">{{
            asset.certificateCode
          }}</span>

          <div v-if="asset.type == 'MOVIE'" class="video-subtitle genres">
            <template v-for="(genre, i) in asset.genres">
              <span>{{ genre }}</span
              >{{ i === asset.genres.length - 1 ? "" : ", " }}
              <!--
						-->
            </template>
          </div>
        </div>
        <h6 class="player-castname" v-if="isCcast">
          Reproduciendo ahora en <b>{{ friendlyName }}</b>
        </h6>
        <!-- <div id="currentTimeCast">00:00:00</div> -->
        <div id="duration">00:00:00</div>
        <div
          :style="controlBarStyle"
          :class="[
            isCcast
              ? 'mini-controller-cast player-video-controlbar'
              : 'player-video-controlbar',
          ]"
        >
          <div class="video-status" id="seekable_window">
            <div
              v-show="!asset.isFakeProgram"
              class="progress video-progress progress2"
              v-on:click.stop="onControlSeek($event)"
              v-on:dblclick.stop=""
            >
              <div class="tooltip bs-tooltip-top" role="tooltip">
                <div class="arrow"></div>
                <div class="tooltip-inner">{{ time.tooltip }}</div>
              </div>

              <div class="progress-bar-live">
                <a
                  v-if="isLive && isLiveStream"
                  v-on:click.stop.prevent="seekByTime(Infinity)"
                  v-on:dblclick.stop=""
                  href="#"
                  class="back-to-live"
                  title="Volver al streaming en vivo"
                  role="button"
                ></a>
              </div>

              <div id="progressVideo" class="progress-bar fill"></div>
            </div>

            <span
              v-show="!asset.isFakeProgram"
              class="time"
              id="currentTime"
              >{{
                isTvShow ? time.position : time.position + " / " + time.duration
              }}</span
            >

            <a
              v-if="rail.show && asset && playerSource && !asset.isFakeProgram"
              class="player-bottom-trigger"
              v-on:click.prevent.stop="loadSuggested()"
              href="#"
              title="Ver más contenido..."
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="32">
                <g fill="none" fill-rule="evenodd">
                  <path
                    fill="#C9E1F2"
                    fill-rule="nonzero"
                    d="M40.8021 10.5833H16.2567c-1.5 0-2.7273 1.05-2.7273 2.3334V29.25c0 1.2833 1.2273 2.3333 2.7273 2.3333H40.802c1.5 0 2.7273-1.05 2.7273-2.3333V12.9167c0-1.2834-1.2273-2.3334-2.7273-2.3334z"
                    class="path path1"
                  />
                  <path
                    fill="#C9E1F2"
                    fill-rule="nonzero"
                    d="M34.3316 5.3333H9.786c-1.5 0-2.7273 1.05-2.7273 2.3334V24c0 1.2833 1.2273 2.3333 2.7273 2.3333h24.5455c1.5 0 2.7272-1.05 2.7272-2.3333V7.6667c0-1.2834-1.2272-2.3334-2.7272-2.3334z"
                    opacity=".5392"
                    class="path path2"
                  />
                  <path
                    fill="#C9E1F2"
                    fill-rule="nonzero"
                    d="M27.861.0833H3.3155c-1.5 0-2.7273 1.05-2.7273 2.3334V18.75c0 1.2833 1.2273 2.3333 2.7273 2.3333H27.861c1.5 0 2.7272-1.05 2.7272-2.3333V2.4167c0-1.2834-1.2272-2.3334-2.7272-2.3334z"
                    opacity=".3387"
                    class="path path3"
                  />
                  <path
                    fill="#020201"
                    fill-rule="nonzero"
                    d="M25.6935 16.5117l8.2617 5.2383-8.2617 5.2383z"
                    opacity=".5409"
                    class="icon-path1"
                  />
                  <path
                    fill="#020201"
                    fill-rule="nonzero"
                    d="M34.2184 17.5664L29.9996 21.75l4.2188 4.1836-1.0547 1.0547-4.2188-4.1836-4.1836 4.1836-1.0547-1.0547 4.1836-4.1836-4.1836-4.1836 1.0547-1.0547 4.1836 4.1836 4.2188-4.1836z"
                    opacity=".75"
                    class="icon-path2"
                  />
                </g>
              </svg>
            </a>
          </div>

          <div class="video-controls">
            <div
              v-if="
                isTvShow && (!playerSource || playerSource.type != 'RECORD')
              "
              class="popover-wrapper"
              v-on:click.stop=""
              v-on:dblclick.stop=""
            >
              <a
                v-if="recordOptions.grabado"
                v-on:click.prevent.stop="onDeleteRecord()"
                href="#"
                class="btn btn-record to-record record-confirmed"
                title="Cancelar grabación"
              >
                <i class="zmdi zmdi-dot-circle-alt"></i>
              </a>
              <a
                v-else-if="recordOptions.blocked"
                v-on:click.prevent=""
                href="#"
                class="btn disabled"
                title="Opción no disponible temporalmente"
              >
                <i class="zmdi zmdi-dot-circle"></i>
              </a>
              <a
                v-else-if="playerSource && playerSource.fechaInicio === 'Hoy' && playerSource.status !=='PASADO'"
                v-on:click.prevent.stop="onBtnRecord()"
                href="#"
                class="btn btn-record"
                title="Programar grabación"
              >
                <i
                  class="zmdi"
                  :class="
                    recordOptions.show
                      ? 'zmdi-time-interval'
                      : 'zmdi-dot-circle'
                  "
                ></i>
              </a>

              <div
                class="popover-content"
                :class="{ show: recordOptions.show }"
              >
                <span class="popover-title">Grabar este programa:</span>

                <form role="form" novalidate>
                  <div class="form-group">
                    <label for="rangeInput">Desde antes del inicio</label>
                    <input
                      v-model="recordOptions.preGap"
                      id="rangeInput"
                      class="tplay-range"
                      type="range"
                      min="0"
                      max="15"
                      step="5"
                      oninput="amount.value=rangeInput.value"
                    />
                    <input
                      v-model="recordOptions.preGap"
                      id="amount"
                      readOnly
                      class="form-control"
                      type="text"
                      value="5"
                      min="0"
                      max="15"
                      oninput="rangeInput.value=amount.value"
                    />
                    <span>min.</span>
                  </div>

                  <div class="form-group">
                    <label for="rangeInput2"
                      >Hasta después de que finalice</label
                    >
                    <input
                      v-model="recordOptions.postGap"
                      id="rangeInput2"
                      class="tplay-range"
                      type="range"
                      min="0"
                      max="15"
                      step="5"
                      oninput="amount2.value=rangeInput2.value"
                    />
                    <input
                      v-model="recordOptions.postGap"
                      id="amount2"
                      readOnly
                      class="form-control"
                      type="text"
                      value="5"
                      min="0"
                      max="15"
                      oninput="rangeInput2.value=amount2.value"
                    />
                    <span>min.</span>
                  </div>

                  <div
                    v-if="asset.serie && asset.serie.idSerie"
                    class="row-switch"
                  >
                    <span>¿Todos los episodios?</span>
                    <div class="tplay-toggle">
                      <input
                        v-model="recordOptions.switchSerie"
                        type="checkbox"
                        id="Player-switch"
                      />
                      <label for="Player-switch">Toggle</label>
                    </div>
                  </div>

                  <div class="row">
                    <button
                      v-on:click.stop="onControlSaveRecord()"
                      type="button"
                      class="btn btn-primary btn-sm"
                    >
                      OK
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!-- .popover-wrapper -->

            <a
              v-if="isLive"
              v-on:click.stop="seekByProgress(0)"
              v-on:dblclick.stop=""
              href="#"
              class="btn btn-back"
              title="Volver a empezar..."
            >
              <i class="zmdi zmdi-skip-previous"></i>
            </a>

            <a
              :class="{ disabled: time.position == '00:00' }"
              v-on:click.prevent.stop="onControlBackward()"
              v-on:dblclick.stop=""
              href="#"
              class="btn"
              title="Retroceder"
            >
              <i class="zmdi zmdi-replay-30"></i>
            </a>
            <a
              :class="{ disabled: isLive && !isLiveStream }"
              v-on:click.prevent.stop="onControlForward()"
              v-on:dblclick.stop=""
              href="#"
              class="btn"
              title="Avanzar"
            >
              <i class="zmdi zmdi-forward-30"></i>
            </a>

            <a
              v-on:click.stop="onControlPlayPause()"
              v-on:dblclick.stop=""
              href="#"
              class="btn btn-play"
              :title="titleBtn"
              id="playPauseButton"
            >
              <label for="btnPlay">
                <input
                  type="checkbox"
                  name="btnPlay"
                  :checked="btnPlayState == 'PLAY'"
                />
                <span
                  v-if="
                    time.position != time.duration || time.duration == '00:00'
                  "
                ></span>
                <i
                  v-else-if="!isLive && time.duration != '00:00'"
                  class="zmdi zmdi-replay"
                  style="padding-top: 15%"
                ></i>
              </label>
            </a>

            <div
              class="audio-subtitle-control"
              v-on:dblclick.stop=""
              v-on:click.stop=""
            >
              <a href="#" class="btn" title="Audio / Subtítulos">
                <i class="zmdi zmdi-comment-more"></i>
              </a>

              <div
                class="dropdown dropdown-audio-subtitle dropup"
                v-on:click.stop=""
              >
                <div class="dropdown-menu">
                  <div class="col-left">
                    <h6 class="dropdown-header">
                      <i class="zmdi zmdi-surround-sound"></i> Audio
                    </h6>
                    <div class="dropdown-divider"></div>
                    <div class="sublist-wrapper">
                      <template v-if="tracks.audio.length > 0">
                        <a
                          v-for="(track, index) in tracks.audio"
                          :key="index"
                          v-on:click.stop="onSetAudioTrack(track)"
                          class="dropdown-item"
                          :class="{
                            active: track['is-active'],
                            disable:
                              track.name === 'nol' ||
                              track.name === 'qaa' ||
                              track.name === 'und',
                          }"
                          href="#"
                        >
                          {{ translateTrackName(track) }}
                        </a>
                      </template>
                      <template v-if="tracks.audio.length <= 0">
                        <span class="dropdown-item not-available"
                          >No disponible</span
                        >
                      </template>
                    </div>
                  </div>

                  <div class="col-right">
                    <h6 class="dropdown-header">
                      <i class="zmdi zmdi-view-subtitles"></i> Subtítulos
                    </h6>
                    <div class="dropdown-divider"></div>
                    <template v-if="tracks.text.length > 0">
                      <a
                        v-on:click.stop="onSetTextTrack(null)"
                        class="dropdown-item"
                        :class="{ active: !selectedTextTrack }"
                        href="#"
                      >
                        Ninguno
                      </a>
                      <a
                        v-for="(track, index) in tracks.text"
                        :key="index"
                        v-on:click.stop="onSetTextTrack(track)"
                        class="dropdown-item"
                        :class="{ active: track['is-active'] }"
                        href="#"
                      >
                        {{ translateTrackName(track) }}
                      </a>
                    </template>
                    <template v-if="tracks.text.length <= 0">
                      <span class="dropdown-item not-available"
                        >No disponible</span
                      >
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="volume-control"
              v-on:click.stop=""
              v-on:dblclick.stop=""
            >
              <a
                v-on:click.stop="onControlMute()"
                href="#"
                class="btn btn-vol"
                title="Volumen"
              >
                <i
                  class="zmdi"
                  :class="[iconMuted]"
                ></i>
              </a>
              <div class="volume-slider">
                <div class="wrap-slider">
                  <input
                    v-on:change.stop="onControlAlterVolume()"
                    type="range"
                    name="volumen"
                    min="0"
                    max="1"
                    step="0.1"
                    value="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="suggested-content"
          v-on:click.stop=""
          v-on:dblclick.stop=""
          v-bind:class="{ 'suggested-content-transparent': !rail.visible }"
        >
          <template v-if="rail.show && rail.list">
            <SelectModal
              v-if="this.asset.serie && this.asset.serie.seasons"
              :title="temporadaText(rail.selectedSeason)"
              :list="temporadas()"
              textField="name"
              @select="selectSeason($event)"
            />

            <h1 v-else class="poster-rail--title">
              <!-- <i class="zmdi zmdi-fire"></i> -->
              <span class="text">Contenido relacionado</span>
            </h1>

            <template v-if="asset.type === 'MOVIE'">
              <Slider :data="rail.list">
                <template slot-scope="itemslider">
                  <CardVOD
                    v-for="(item, index) of itemslider.data"
                    :key="item.idAsset"
                    :data="item"
                    :showMoreInfo="false"
                    :class="{ 'tile-active': railActiveItem === item }"
                    :showProgress="railActiveItem !== item"
                    componentOrigin="ZB"
                    :zapflag="zapFlagParentalControl"
                    :sliderId="'contenido-relacionado'"
                    :sliderTitle="'Contenido relacionado'"
                    :cardId="'card-' + index"
                  />
                </template>
              </Slider>
            </template>
            <template v-else-if="asset.type === 'CHAPTER'">
              <Slider
                :data="rail.list"
                :initialposition="railActiveIndex - 1"
                :showactive="true"
              >
                <template slot-scope="itemslider">
                  <CardChapter
                    v-for="(item, index) of itemslider.data"
                    :key="item.idAsset"
                    :data="item"
                    @play="$bus.$emit('player:set-source', item)"
                    :class="{ 'tile-active': railActiveItem === item }"
                    :showProgress="railActiveItem !== item"
                    componentOrigin="ZB"
                    :zapflag="zapFlagParentalControl"
                    :sliderId="'contenido-relacionado'"
                    :sliderTitle="'Contenido relacionado'"
                    :cardId="'card-' + index"
                  />
                </template>
              </Slider>
            </template>
            <template v-else-if="isTvShow">
              <Slider
                :data="rail.list"
                :initialposition="railActiveIndex - 1"
                :showactive="true"
              >
                <template slot-scope="itemslider">
                  <CardTV
                    v-for="(item, index) of itemslider.data"
                    :key="item.channel.id + '-' + item.idAsset"
                    :data="item"
                    :showMoreInfo="false"
                    :showChannelButton="false"
                    :class="{ 'tile-active': railActiveItem === item }"
                    :showProgress="railActiveItem !== item"
                    componentOrigin="ZB"
                    :zapflag="zapFlagParentalControl"
                    :sliderId="'contenido-relacionado'"
                    :sliderTitle="'Contenido relacionado'"
                    :cardId="index"
                  />
                </template>
              </Slider>
            </template>
          </template>
        </div>
      </div>

      <div
        v-if="showLoader && !loaderDial"
        class="loader-wrapper loader-component"
      >
        <div class="loader" title="Cargando ...">
          <svg class="circular-loader" viewBox="25 25 50 50">
            <circle
              class="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#09f"
              stroke-width="4"
            />
          </svg>
        </div>
      </div>
    </div>
    <DialModal ref="dial" @showLoaderDial="showLoaderDial" />
  </div>
</template>

<script>
import IMG_NO_POSTER_16_9 from "@/assets/images/no-poster-16-9.png";

import * as storeCast from "@/observables/cast";
import { getMsgEventFor, getEventContextFor } from "../helpers/eventMsg";
import * as sc from "@/services/streamConcurrency";

const _MSG_CODES = {
  // 1001
  LOAD: "LOAD",
  // 1002
  START: "START",
  // no usado
  PROGRESS: "PROGRESS",
  // 1004
  POSITION: "POSITION",
  // 1005
  COMPLETED: "COMPLETED",
  // 1006
  PAUSE: "PAUSE",
  // 1007
  STOP: "STOP",
  // 1008
  RESUME: "RESUME",
  // 1051
  PLAYBACKERROR: "PLAYBACKERROR",
};

const _PLAYBACK_ERROR_CODES = {
  // stream concurrency problem
  MAX_SESSIONS_REACHED: -440,
  DUPLICATED_PLAYBACK: "duplicated_playback",
};

let bcSc = null;

const _PLAYBACK_INTENTS = 2;

export default {
  name: "Player",

  inject: ["modalConfirmShow", "isModalPinOpened"],

  components: {
    CardVOD: () => import("@/components/Card-VOD.vue"),
    CardChapter: () => import("@/components/Card-chapter.vue"),
    CardTV: () => import("@/components/Card-TV.vue"),
    Slider: () => import("@/components/Slider.vue"),
    SelectModal: () => import("@/components/SelectModal.vue"),
    DialModal: () => import("@/components/DialModal.vue"),

    Ccast: () => import("@/components/Ccast.vue"),
  },

  data() {
    return {
      vip: {},
      localStore: {},
      video: null,
      objProgress: {
        progressLive: null,
        progress: null,
        fill: null,
        seekRange: null,
      },
      proximoAsset: null,
      showLoader: true,
      loaderDial: false,
      isLive: true,
      numberTimeFormat: this.timeFormat(),
      time: {
        tooltip: "00:00",
        duration: "00:00",
        position: "00:00",
        timeInfo: {},
      },
      isLiveStream: false,
      asset: {
        startHourUnixTime: 0,
        endHourUnixTime: 0,
      },
      tracks: {
        audio: [],
        text: [],
      },
      dialNumber: "",
      lastSelectedAudioTrackName: null,
      lastSelectedTextTrackName: null,
      playerSource: null,
      seconds: 0,
      timerPosition: null,
      setSourceTimer: 0,
      recordOptions: tplay.recordOptionsDefault(),
      stream: null,
      titleBtn: "Reproducir",
      btnPlayState: null,
      seekTime: 0, //Posición en donde se dejó la reproducción la última vez (en segundos)
      tplayPlayer: null, //instancia que se devuelve en tplay-player.js
      state: "STOP", //Estado del reproductor. Posibles: INIT, LOAD, START, PAUSE, STOP, END, ERROR
      rail: {
        list: null, //Lista de assets que se muestran como sugeridos
        visible: false, //Indica si actualmente el rail es visible o no
        show: true, //Indica si el rail está presente o no
        selectedSeason: null, //Temporada seleccionada en el select del rail (sólo en caso de ser una serie)
        serie: null,
        animating: false, //Flag que indica si se está ejecutando una animación, para impedir el solapamiento de animaciones
      },
      zapFlagParentalControl: null,
      timerTimeParentalControl: null,
      runingTime: 0,
      timeToStart: 10, // Tiempo de espera continuar viendo en segundos3
      isCcast: false,
      sourceLoaded: false,
      lastSource: null,
      calledPositionCast: false,
      checkMuted: false,
      hiddePlayerOnPin: false,
      // playerStatus: null
      timerAudience: 0,
      timerInterval: null,
      onErrorIntent: 0,
      completedSend: false,
    };
  },

  mounted() {
    const supportsVideo = !!document.createElement("video").canPlayType;
    const self = this;
    const video = document.getElementById("video");
    const op = this.getControlProgress();
    const playerContainer = document.getElementById("player-container");

    window.addEventListener("beforeunload", self.leaving);

    sc.init();

    self.video = video;
    self.objProgress = op;

    if (supportsVideo) {
      op.progress.addEventListener("mousemove", self.onProgressMouseMove);
      self.$el.addEventListener("click", self.onElementClick);
      self.initVipPlayer(video);
    }

    self.$bus.$on("remote:seek-time", function (time) {
      self.calledPositionCast = true;
      /* const op = self.getControlProgress();
			self.objProgress = op;
			self.time.position = self.durationToLabel(time); */
    });
    self.$bus.$on("player-cast-ready", () => {
      window.vip.player.play();
    });
    self.$bus.$on("cast-setSourcePlayer", () => self.setSourceCast());
    self.$bus.$on("player:set-source", function (obj, opt) {
      // Connection to a broadcast channel
      bcSc = new BroadcastChannel(sc.getBroadcastChannel());
      // A handler that only logs the event to the console:
      bcSc.onmessage = (event) => {
        const msgError = {
          code: _PLAYBACK_ERROR_CODES.DUPLICATED_PLAYBACK,
          detail: "Ya existe una reproducción activa",
        };
        self.manageError(msgError);
      };

      // window.setTimeout(() => {
      // 	self.closeByConcurrency();
      // },10000);

      if (window.vip.player.playing) {
        self.sendMSG(_MSG_CODES.STOP);
      }

      if (!supportsVideo) {
        self.manageError("Tu navegador no soporta la reproducción de video.");
        return;
      }

      if (!this.isCcast) {
        self.resetData();
      }

      if (opt && opt.rail) {
        if (typeof opt.rail.show !== "undefined") {
          self.rail.show = opt.rail.show;
        }
        if (typeof opt.rail.list !== "undefined") {
          self.rail.list = opt.rail.list;
        }
      }

      self.titleBtn = "Pausa";
      self.btnPlayState = "PAUSE";

      self.playerSource = obj;

      //Es necesario actualizar ahora el estado y actualizar el idAsset, para mantener consistentes las cards
      //hasta que se termine de cargar el video.
      //Es muy importante el orden, porque setear state se avisa a las cards del cambio de estado, y
      //es necesario que el idAsset esté ya seteado.
      //Y además, es necesario asignar un objeto nuevo al asset, porque de lo contrario se estaría modificando
      //la propiedad idAsset del video anterior, y eso podría llevar a modificar el idAsset de una card por ejemplo.
      //Esto último se logra con resetData().

      self.asset.idAsset = obj.idAsset;
      self.state = "INIT";

      self.isLive = !!obj.tvshow;

      //Solamente instancio tplayPlayer la primera vez:
      if (!self.tplayPlayer) {
        self.tplayPlayer = tplay.Player();
      }

      //Botones de pip y pantalla completa
      self.tplayPlayer.showModeButtons(true);

      //Muestro el player:
      self.tplayPlayer.show();

      self.tplayPlayer.showControls();
      playerContainer.classList.remove("user-inactive");
      playerContainer.classList.add("user-active");

      if (obj.type == "SERIE") {
        this.$bus.$emit("set-cast-subtitle", "");
        //Si es una serie, primero necesito saber si el usuario tiene algún episodio
        //activo, así le pregunto si quiere ver ese o ver desde el inicio, y recién
        //después puedo llamar a getPlayerSource().

        telecentro.tplay.core.biz.series.getCapituloActivo(
          obj,
          function (rActivo) {
            if (
              rActivo.capituloActivo &&
              (rActivo.capituloActivo !== rActivo.primerCapitulo ||
                rActivo.primerCapitulo.seekTime > 0)
            ) {
              //El usuario tiene un episodio activo.
              //Pido confirmación al usuario para avanzar hasta el punto de la última reproducción
              self.confirmPlayerSeekTime(
                obj.type,
                rActivo.capituloActivo,
                function (ok) {
                  self.sourceLoaded = true;
                  let source = ok
                    ? rActivo.capituloActivo
                    : rActivo.primerCapitulo;
                  if (!ok) {
                    telecentro.tplay.core.biz.vod.resetActiveAsset(obj);
                  }

                  telecentro.tplay.core.biz.playerSource.getPlayerSource(
                    source,
                    function (source) {
                      if (source.seekTime > 0) {
                        self.seekTime = ok ? source.seekTime : 0;
                        self.setSource(source);
                      } else {
                        self.setSource(source);
                      }
                    },
                    (r) => {
                      self.manageError(r, false);
                    }
                  );
                }
              );
            } else {
              telecentro.tplay.core.biz.playerSource.getPlayerSource(
                rActivo.primerCapitulo,
                self.setSource,
                (r) => {
                  self.manageError(r, false);
                }
              );
            }
          }
        );
      } else if (obj.type == "MOVIE" || obj.type == "CHAPTER") {
        this.$bus.$emit("set-cast-subtitle", "");
        telecentro.tplay.core.biz.playerSource.getPlayerSource(
          obj,
          function (source) {
            delete source.asset.serie;
            if (source.seekTime > 0) {
              //Pido confirmación al usuario para avanzar hasta el punto de la última reproducción
              self.confirmPlayerSeekTime(obj.type, source.asset, function (ok) {
                self.sourceLoaded = true;
                self.seekTime = ok ? source.seekTime : 0;
                self.setSource(source);
              });
            } else {
              self.setSource(source);
            }
          },
          (r) => {
            self.manageError(r, false);
          }
        );
      } else if (obj.status == "PRESENTE") {
        telecentro.tplay.core.epg.eventoCambioProgramacion.on(
          self.onCambioProgramacion
        );
        telecentro.tplay.core.biz.playerSource.getPlayerSource(
          obj,
          self.setSource,
          (r) => {
            self.manageError(r, false);
          }
        );
      } else {
        telecentro.tplay.core.biz.playerSource.getPlayerSource(
          obj,
          self.setSource,
          (r) => {
            self.manageError(r, false);
          }
        );
      }
    });

    this.$bus.$on(
      "cast:connected",
      (isConected, estimatedRemoteMediaTime = null, isMuted = false) => {
        //console.log('cast:connected', isConected);
        this.isCcast = isConected;
        if (isConected && this.playerSource) {
          //this.video.muted = true;
          window.vip.player.setVolume({muted: true});
          if(window.tplay.ccast.remotePlayer.isMuted){
            this.checkMuted = true;
          } else {
            this.checkMuted = false;
          }
          
          window.vip.player.pause();
        }
        if (!isConected) {
          if (estimatedRemoteMediaTime !== null) {
            this.calledPositionCast = false;
            //this.video.muted = false;
            const volumeInfo = window.vip.player.getVolume();
            if(isMuted){
              window.vip.player.setVolume({muted: true});
              this.checkMuted = true;
            } else {
              window.vip.player.setVolume({muted: false});
              this.checkMuted = false;
            }
            if(window.vip.player.playing) {
              window.vip.player.seekTo(estimatedRemoteMediaTime * 1000);
              window.vip.player.play();
            }
          }
        }
      }
    );
    this.$bus.$on("close-player", () => {
      document.getElementById("btn-exit").click();
    });
  },

  computed: {
    iconMuted() {
      return this.checkMuted === false ? 'zmdi-volume-up' : 'zmdi-volume-off';
    },
    friendlyName() {
      let castSession = "";
      if (storeCast.haveSupport()) {
        if (cast.framework.CastContext.getInstance().getCurrentSession()) {
          castSession = cast.framework.CastContext.getInstance()
            .getCurrentSession()
            .getCastDevice().friendlyName;
        }
      }
      return castSession;
    },

    nTitle() {
      return this.asset.serie_name || this.asset.title;
    },

    temporadaEpisodio() {
      return [
        this.asset.n_temporada ? "T" + this.asset.n_temporada : null,
        this.asset.n_episodio ? "E" + this.asset.n_episodio : null,
      ]
        .filter((x) => x)
        .join(":");
    },

    isTvShow() {
      return (
        !this.asset.type ||
        (this.asset.type != "MOVIE" &&
          this.asset.type != "SERIE" &&
          this.asset.type != "CHAPTER")
      );
    },

    hasChannelLogo() {
      return !!(this.asset.channel && this.asset.channel.logo);
    },

    //Determina si el player está listo para recibir acciones, como play o pause
    isReady: function () {
      return (
        this.state == "LOAD" ||
        this.state == "START" ||
        this.state == "PAUSE" ||
        this.state == "END"
      );
    },

    controlBarStyle() {
      //Si isReady reseteo el display
      return this.isReady ? {} : { display: "none !important" };
    },

    playerType() {
      if (this.asset.type === "MOVIE") return "type-movie";
      if (this.asset.type === "SERIE" || this.asset.type === "CHAPTER")
        return "type-serie";
      return "type-live";
    },

    selectedAudioTrack() {
      for (let track of this.tracks.audio) {
        if (track["is-active"]) {
          return track;
        }
      }

      return null;
    },

    selectedTextTrack() {
      for (let track of this.tracks.text) {
        if (track["is-active"]) {
          return track;
        }
      }

      return null;
    },

    timeText() {
      const asset = this.asset;
      let text = "";

      if (asset.fechaInicio) {
        text += asset.fechaInicio;
      }
      if (asset.startHour && asset.endHour) {
        text += " de " + asset.startHour + " a " + asset.endHour + " hs.";
      }

      return text;
    },

    railActiveIndex() {
      const self = this;

      if (!this.rail.show || !this.rail.list) return -1;

      return this.rail.list.findIndex(
        (item) => item.idAsset === self.asset.idAsset
      );
    },

    railActiveItem() {
      if (this.railActiveIndex < 0) return null;

      return this.rail.list[this.railActiveIndex];
    },
  },

  methods: {
    temporadas() {
      return this.asset.serie.seasons.map(season => {
        return {
          ...season,
          name: this.temporadaText(season)
        }
      })
    },
    temporadaText(season) {
            let textoTemporada = '';
            if(season.capitulos === undefined){
              return season.name;
            }
            if(season.capitulos[0].n_temporada === undefined){
                if(season.name && (season.name.toUpperCase().indexOf('TEMPO') > -1 || season.name.toUpperCase().indexOf('T ') > -1) ){
                    textoTemporada = season.name;
                } else if(season.name) {
                    textoTemporada = `Temporada ${season.name}`;
                } else {
                    textoTemporada = `Temporada ${season.n_temporada}`;
                }
            } else {
                textoTemporada = `Temporada ${season.capitulos[0].n_temporada}`;
            }
            return textoTemporada;
    },
    leaving() {
      if (window.vip.player.playing) {
        this.sendMSG(_MSG_CODES.STOP);
      }
    },
    isCastAvailable() {
      return storeCast.haveSupport();
    },
    setSourceCast() {
      const self = this;
      console.info("se llama a load desde dentro player");
      this.isCcast = true;
      const op = self.getControlProgress();
      self.objProgress = op;
      if (op && op.progress) {
        op.progress.removeEventListener("mousemove", self.onProgressMouseMove);
      }
      op.progress.addEventListener("mousemove", self.onProgressMouseMove);
      this.$bus.$emit("cast-load");
      setTimeout(() => {
        this.$bus.$emit("set-cast-title", this.nTitle);
        this.$bus.$emit("set-cast-subtitle", this.timeText);
      }, 1);
    },
    setIsPlayer() {
      tplay.ccast.fromPlayer = true;
    },
    showLoaderDial(set) {
      this.loaderDial = set;
    },

    keyboardControls(key) {
      let MaaskModalGeneric = document.querySelector(".modal-backdrop");

      //Si el Player aún no está listo, o está oculto, o está en PIP, no respondo a los atajos de teclado
      if (
        !this.isReady ||
        document.querySelector(".player.player-hide") ||
        this.tplayPlayer.mode === "pip"
      ) {
        return;
      }

      key.preventDefault();
      switch (key.keyCode) {
        // Esc
        // Exit player
        case 27:
          if (MaaskModalGeneric) {
            document.querySelector(".modal-backdrop").style.display = "none";
            // window.location.reload();
          }
          // document.querySelector('.modal-ready').style.display = 'none';
          if (!MaaskModalGeneric) {
            document.querySelector(".player .btn-exit").click();
          }
          break;

        // Enter
        // Space
        // Pause or play video
        case 13:
          break;
        case 16:
          return null;
        case 32:
          this.onControlPlayPause();
          break;

        // ArrowUp
        // Increase volume by 10%. OLD
        // Channel Zapping UP.
        case 38:
          if (
            this.asset.type === "MOVIE" ||
            this.asset.type === "SERIE" ||
            this.asset.type === "CHAPTER"
          ) {
            return null;
          } else {
            this.$refs.dial.zapChannel(this.asset.channel, "up");
          }

          // document.querySelector('[name="volumen"]').value = parseInt(document.querySelector('[name="volumen"]').value) + 10;
          // this.onControlAlterVolume();
          //t
          break;

        // ArrowDown
        // Decrease volume by 10%. OLD
        // Channel Zapping DOWN.
        case 40:
          if (
            this.asset.type === "MOVIE" ||
            this.asset.type === "SERIE" ||
            this.asset.type === "CHAPTER"
          ) {
            return null;
          } else {
            this.$refs.dial.zapChannel(this.asset.channel, "down");
          }
          // document.querySelector('[name="volumen"]').value = parseInt(document.querySelector('[name="volumen"]').value) - 10;
          // this.onControlAlterVolume();
          // this.$refs.dial.zapChannel(this.asset.channel, 'down');
          break;

        // ArrowLeft
        // Seek backward by 30 seconds.
        case 37:
          this.onControlBackward();
          break;

        // ArrowRight
        // Seek forward by 30 seconds.
        case 39:
          this.onControlForward();
          break;

        // KeyF
        // Enter or exit fullscreen
        case 70:
          document.querySelector(".player .btn-fullscreen .zmdi").click();
          break;

        // KeyM
        // Mute or unmute video volume
        case 77:
          document.querySelector(".player .btn-vol").click();
          break;

        // Numberkeys
        // Channel Selection by numbers
        case 48:
        case 49:
        case 50:
        case 51:
        case 52:
        case 53:
        case 54:
        case 55:
        case 56:
        case 57:
        // Numbers keypad
        case 96:
        case 97:
        case 98:
        case 99:
        case 100:
        case 101:
        case 102:
        case 103:
        case 104:
        case 105:
          //console.log('digit key', key.key);
          // controlo si el modal de pin ya esta abierto para no interferir en el ingreso de caracteres.
          if (!this.isModalPinOpened()) {
            this.$refs.dial.dialNumber(key.key, this.asset.channel);
          }

          break;

        default:
          break;
      }
    },

    recoverPlayback() {
      const self = this;
      //console.log('recuperar sesion de playback');
      const asset = Object.assign({}, self.asset);
      this.$bus.$emit(
        "show-toast",
        "info",
        "Intentando recuperar la reproducción."
      );
      self.$bus.$emit("player:set-source", asset);
    },

    manageError(error = "", recover = false) {
      if (recover && this.onErrorIntent < _PLAYBACK_INTENTS) {
        this.onErrorIntent++;
        this.recoverPlayback();
        return;
      }

      this.onErrorIntent = 0;

      if (error === "Ocurrió un error.") {
        error = {
          code: "core_loadSource",
          message: undefined,
        };
      }

      const msgError = {
        code: "no_error_code",
        detail: "no_error_detail",
      };

      bcSc.close();

      if (error) {
        if (error.Wj) {
          msgError.code = error.Wj.code;
          msgError.detail = `${error.Wj.category} - ${error.Wj.message}`;
        } else if (error.code) {
          const detail =
            error.originalMessage ||
            error.message ||
            "El contenido no está disponible en este momento.";
          msgError.code = error.code;
          msgError.detail = `${detail}`;
        }
      }

      if (msgError.code === _PLAYBACK_ERROR_CODES.MAX_SESSIONS_REACHED) {
        //this.$bus.$emit('modal-concurrency:show', this.playerSource);
        // le debo dar un tiempo a la función del modal que decontruye (si había otro modal abierto)
        // para que aplique los cambios en el dom.
        setTimeout(() => {
          this.concurrencyConfirmModal();
        }, 300);

        this.closeByConcurrency();
        return;
      }

      if (msgError.code === _PLAYBACK_ERROR_CODES.DUPLICATED_PLAYBACK) {
        this.duplicatedPlaybackConfirmModal();
        this.closeByDuplicatedPlayback();
        return;
      }

      this.sendMSG(_MSG_CODES.PLAYBACKERROR, msgError);

      var mensaje =
        error != ""
          ? msgError.detail
          : "Ha ocurrido un error inesperado. Intente nuevamente.";

      if (document.createElement("video").canPlayType("audio/aac") === "") {
        mensaje = "Formato de audio o video no soportado por el navegador.";
      }

      this.$bus.$emit("show-toast", "error", mensaje);
      this.resetData();

      if (this.timerPosition) {
        window.clearInterval(this.timerPosition);
        this.timerPosition = null;
      }
      if (this.timerTimeParentalControl) {
        window.clearInterval(this.timerTimeParentalControl);
        this.timerTimeParentalControl = null;
      }

      setTimeout(() => {
        tplay.Player.animateHide();
      }, 500);
    },

    translateTrackName(track) {
      return telecentro.tplay.core.lang.getNativeName(track.name);
    },
    getTimePosition() {},
    assetIsLive(assetSource) {
      if (assetSource.status === "PRESENTE") {
        return true;
      } else {
        return false;
      }
    },
    sendMSG(event, error = null) {
      const self = this;

      let a = this.time.position.split(":");

      // dejo esto comentado por si en algun momento hay que usarlo.
      // por ahora el campo de bitrate se va a completar con el streamBandwidth por recomendacion de Frederic de alpha en el canal de slack
      // const bpp = 24; // asumo que existen 24 bits por pixel
      // let bitrate = (status.width * status.height * (status.decodedFrames / 60) * bpp);

      // '00:00' || '00:00:00'
      if (a.length <= 2) {
        a[2] = a[1];
        a[1] = a[0];
        a[0] = 0;
      }

      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

      let eventName = getMsgEventFor(event, this.asset);
      let context = getEventContextFor(event, this.asset, seconds);

      // context.bitrate = self.playerStatus?.currentTarget?.lastStreamBandwidth; // lo debo obtener del playerstatus

      telecentro.tplay.core.msg.sendEvent(
        eventName,
        this.asset,
        context,
        error
      );
      // console.log("seek_msg idAsset: " + this.asset.idAsset + " seconds:" + seconds + ' event: ' + event)

      //const parentalControlCheck = telecentro.tplay.core.biz.parentalControl.blockAsset(this.asset);

      // Si se está reproduciendo
      if (
        event === _MSG_CODES.START ||
        event === _MSG_CODES.LOAD ||
        event === _MSG_CODES.RESUME ||
        event === _MSG_CODES.PAUSE
      ) {
        // Si no me encuentro suscripto
        if (!this.timerPosition) {
          window.vip.player.getTimeInfo().then((time) => {
            if (time.isLive || self.asset.type === "RECORD") {
              seconds = {
                livePosition: time.seekRange[1] / 1000,
                bufferStartPosition: time.seekRange[0] / 1000,
                playbackPosition: time.position / 1000,
              };
            }
            eventName = getMsgEventFor(_MSG_CODES.POSITION, self.asset);
            context = getEventContextFor(
              _MSG_CODES.POSITION,
              self.asset,
              seconds
            );
            telecentro.tplay.core.msg.sendEvent(eventName, self.asset, context);
          });

          let intervalTime = 30; /*segun lo especificado*/
          // Me suscribo al position
          this.timerPosition = setInterval(function () {
            // dejo esto comentado por si en algun momento hay que usarlo.
            // por ahora el campo de bitrate se va a completar con el streamBandwidth por recomendacion de Frederic de alpha en el canal de slack
            // const bpp = 24; // asumo que existen 24 bits por pixel
            // let bitrate = (status.width * status.height * (status.decodedFrames / 60) * bpp);

            // console.log('[PLAYER] -> sendMSG.status ', status);

            let a = self.time.position.split(":");

            if (a.length <= 2) {
              a[2] = a[1];
              a[1] = a[0];
              a[0] = 0;
            }

            let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

            // console.log('timeInSeconds',self.timeInSeconds(self.time.timeInfo.position));

            window.vip.player.getTimeInfo().then((time) => {
              if (time.isLive || self.asset.type === "RECORD") {
                seconds = {
                  livePosition: time.seekRange[1] / 1000,
                  bufferStartPosition: time.seekRange[0] / 1000,
                  playbackPosition: time.position / 1000,
                };
              }

              if(!time.isLive && !self.completedSend) {
                  let percentToComplete = time.position * 100 / time.duration;
                  if(percentToComplete >= 95){
                      eventName = getMsgEventFor(_MSG_CODES.COMPLETED, self.asset);
                      context = getEventContextFor(_MSG_CODES.COMPLETED, self.asset, seconds);
                      telecentro.tplay.core.msg.sendEvent(eventName, self.asset, context);
                      self.completedSend = true;
                  }
              }



              eventName = getMsgEventFor(_MSG_CODES.POSITION, self.asset);
              context = getEventContextFor(
                _MSG_CODES.POSITION,
                self.asset,
                seconds
              );
              // context.bitrate = self.playerStatus?.currentTarget?.lastStreamBandwidth; // lo debo obtener del playerstatus

              telecentro.tplay.core.msg.sendEvent(
                eventName,
                self.asset,
                context
              );
            });

            // if(self.time.timeInfo.isLive){
            // 	seconds = {
            // 		livePosition: self.timeInSeconds(self.time.timeInfo.buffers[0].end),
            // 		bufferStartPosition: self.timeInSeconds(self.time.timeInfo.buffers[0].start),
            // 		playbackPosition: self.timeInSeconds(self.time.timeInfo.position),
            // 	}
            // }

            // console.log("seek_msg idAsset: " + self.asset.idAsset + " seconds:" + seconds + ' event: ' + event)

            if (self.timerAudience >= 1 * 60 * 1000) {
              self.timerAudience = 0;
              tplay.sendAudience(self.asset);
            } else {
              self.timerAudience += 5 * 1000;
            }
          }, intervalTime * 1000);
        }
      } else if (event === "POSITION") {
        // avoid to unsubscribe interval
      } else {
        // Si paro el video por algún motivo
        // Me desuscribo
        window.clearInterval(this.timerPosition);
        this.timerPosition = null;
      }
    },
    conversionMinutesToSecondsCP() {
      let ParentalControlTimeMin =
        telecentro.tplay.core.biz.parentalControl.getRemember();
      let ParentalControlTimeSeconds = ParentalControlTimeMin * 60;

      return ParentalControlTimeSeconds;
    },
    resetData() {
      const vipPlayer = window.vip.player;

      window.vip.player.removeEventListener(
        "status",
        this.onPlayerStatus.bind(this),
        false
      );

      tplay.Player.runInhidenControls = this.slideSuggested;
      tplay.Player.suggestedContentVisible = false;

      // if (this.isActive()) {
      // 	this.sendMSG(_MSG_CODES.STOP);
      // }

      if (vipPlayer) {
        vipPlayer.freeze();
      }

      if (this.video) {
        this.video.removeEventListener("timeupdate", this.updateProgress);
        this.video.removeEventListener("play", this.onPlayEvent);
        this.video.removeEventListener("pause", this.onPauseEvent);
      }

      telecentro.tplay.core.epg.eventoCambioProgramacion.off(
        this.onCambioProgramacion
      );

      if (this.objProgress.progressLive) {
        this.objProgress.progressLive.style.width = "0";
      }

      if (this.objProgress.fill) {
        this.objProgress.fill.style.width = "0";
      }

      if (this.rail.visible) {
        //Si estaban mostrando los sugeridos, los oculto
        this.slideSuggested(false);
      }

      if (this.timerPosition) {
        window.clearInterval(this.timerPosition);
        this.timerPosition = null;
      }
      if (this.timerTimeParentalControl) {
        window.clearInterval(this.timerTimeParentalControl);
        this.timerTimeParentalControl = null;
      }

      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }

      //Como resetVueData resetea todo, guardo los datos que quiero persistir
      const dataPersistente = {
        video: this.video,
        objProgress: this.objProgress,
        lastSelectedAudioTrackName: this.lastSelectedAudioTrackName,
        lastSelectedTextTrackName: this.lastSelectedTextTrackName,
        tplayPlayer: this.tplayPlayer,
        loaderDial: this.loaderDial,
        isCcast: this.isCcast,
        onErrorIntent: this.onErrorIntent,
      };

      //Reseteo data a los valores default:
      tplay.resetVueData(this);

      //Reestablezco lo que no quería borrar
      Object.assign(this.$data, dataPersistente);
    },

    removeVipEvents() {
      const self = this;
      return new Promise((resolve) => {
        window.removeEventListener(
          "vipplayerstatus",
          self.onPlayerStatus,
          false
        );
        //window.removeEventListener('vipplayerevent', onPlayerEvent, false);
        resolve(true);
      });
    },

    addVipEvents() {
      const self = this;
      return new Promise((resolve) => {
        window.addEventListener("vipplayerstatus", self.onPlayerStatus, false);
        //window.addEventListener('vipplayerevent', onPlayerEvent, false);
        resolve(true);
      });
    },

    initVipPlayer(videoElement) {
      const self = this;

      const options = {
        app_key: vipConfig.CONFIG_VIP_ANALYTICS_API_KEY,
        app: {
          name: telecentro.tplay.core.user.getAppId(),
          version: vip.CONFIG_VIP_BUILD_ID,
        },
      };

      window.vip
        .setup(options, "default", videoElement)
        .then((response) => {
          // window.vip.player.getInstance().setup();
          //console.log('[ PLAYER ] --> vip setup');

          //window.vip.player.getInstance().start();

          // self.removeVipEvents()
          //     .then(self.addVipEvents);

          window.vip.player = response.player;

          window.vip.player.addEventListener(
            "status",
            self.onPlayerStatus.bind(self),
            false
          );

          window.vip.player.setup({
            "verimatrix.server":
              window.vip.CONFIG_VIP_VERIMATRIX_DEFAULT_SERVER,
            "verimatrix.company":
              window.vip.CONFIG_VIP_VERIMATRIX_DEFAULT_COMPANY,
          });
          window.vip.player
            .getDrmProperty("verimatrix.id")
            .then((idVerimatrix) => {
              console.error("verimatrix id is :", idVerimatrix);
              //this.idVerimatrixMonitor = idVerimatrix;
              //telecentro.tplay.core.localStorageFake.setItem('tplayDeviceUniqueId', idVerimatrix);
              //this.doPlay();
            })
            .catch((reason) => {
              console.error(
                "error while getting the verimatrix id reason : " + reason
              );
            });

          //window.vip.player.start();

          self.localStore.player = response.player;
          window.vip.reporter = response.reporter;

          window.addEventListener("keyup", self.keyboardControls, false);
        })
        .catch((error) => {
          console.log("unable to initialize vip: " + error);
        });

      // window.vip.setup(
      // 	function () {
      // 		window.vip.player.getInstance().setupDefaults({
      // 			'verimatrix.server': window.vip.CONFIG_VIP_VERIMATRIX_DEFAULT_SERVER,
      // 			'verimatrix.company': window.vip.CONFIG_VIP_VERIMATRIX_DEFAULT_COMPANY
      // 		});

      // 		window.vip.player.getInstance().setup();

      // 		window.addEventListener('vipplayerstatus', self.onPlayerStatus.bind(self), false);
      // 		window.vip.player.getInstance().start();
      // 		window.addEventListener('keyup', self.keyboardControls.bind(self), false);

      // 	},
      // 	function (error) {
      // 		console.log('unable to initialize vip: ' + error);
      // 	},
      // 	{
      // 		key: window.vip.CONFIG_VIP_ANALYTICS_API_KEY,
      // 		userid: telecentro.tplay.core.user.getAppId(),
      // 		no_reporting: false,
      // 		element: videoElement
      // 	}
      // );
    },

    /**
     *
     * @param {*} source
     */

    setSource(source) {
      //Por si se salió del Player u ocurrió un error mientras se completaba la llamada asincrónica
      if (this.state === "STOP" || this.state === "ERROR") {
        this.resetData();
        return;
      }

      let self = this;

      // Fix eliminar subs
      let imgSubs = this.video.nextSibling;

      if (imgSubs.tagName == "IMG") {
        this.video.parentElement.removeChild(imgSubs);
      }

      self.asset = source.tvshow || source.asset;

      if (self.asset.grabado) {
        self.recordOptions.grabado = self.asset.grabado();
      } else if (self.playerSource.grabado) {
        self.recordOptions.grabado = self.playerSource.grabado();
      }

      source.name = self.asset.title;
      source.content_name = source.name;

      // console.log('----------', source, '----------');

      self.stream = source;

      //desarrollo/tareas#1579 [VUE] Workaround errores setSource Player
      self.setSourceTimer = setTimeout(function () {
        if (this.isCcast) return;
        //Si se llama a esta función, significa que el setSource está tardando demasiado.
        if (storeCast.haveSupport()) {
          const session =
            cast.framework.CastContext.getInstance().getCurrentSession();
          if (session) session.endSession();
        }
        self.manageError("No se pudo obtener el contenido de reproducción.");
      }, 10000); //10 segundos

      if (!sc.canPlayback()) {
        const scTabId = sc.takeControl();
        bcSc.postMessage(sc.getBroadcastMessage());
      }

      const options = source.options ? Object.assign({}, source.options) : {};
      options.autoplay = true;
      options.startPosition = self.seekTime * 1000; // según especificación de ayuda de veigo player.

      window.vip.player
        .setSource(source, options)
        .then(() => {
          //console.log("[ PLAYER ] --> setSource", source);
          let muted = false;
          if(this.isCcast){
            if(window.tplay.ccast.remotePlayer.isMuted){
              muted = true;
            }
          } else {
            const volumeInfo = window.vip.player.getVolume();
            if(volumeInfo.muted){
              muted = true;
              this.checkMuted = true;
            }
          }
          this.checkMuted = muted;
        })
        .catch((error) => {
          console.error("[ PLAYER ] --> setSource", error);
          this.manageError(error, true);
        });

      //se agrega
      localStorage.setItem("mediaSource", JSON.stringify(source));

      if (this.isCcast) {
        console.info("load called");
        const op = self.getControlProgress();
        self.objProgress = op;
        if (op && op.progress) {
          op.progress.removeEventListener(
            "mousemove",
            self.onProgressMouseMove
          );
        }
        op.progress.addEventListener("mousemove", self.onProgressMouseMove);
        this.$bus.$emit("cast-load", source);
      }

      // this.setupLocalPlayer(source);

      self.video.addEventListener("timeupdate", self.updateProgress);
      self.video.addEventListener("play", self.onPlayEvent);
      self.video.addEventListener("pause", self.onPauseEvent);
      self.startTimer();
    },
    decreaseNumber(asset) {
      const self = this;
      let actualTime = this.timeToStart;
      const decrease = setInterval(() => {
        if (self.sourceLoaded) clearInterval(decrease);
        if (actualTime <= 0) {
          clearInterval(decrease);
          if (!self.sourceLoaded) {
            switch (asset.type) {
              case "MOVIE":
                telecentro.tplay.core.biz.playerSource.getPlayerSource(
                  asset,
                  function (source) {
                    if (source.seekTime > 0) {
                      self.seekTime = source.seekTime;
                      self.setSource(source);
                    } else {
                      self.setSource(source);
                    }
                  },
                  (r) => {
                    self.manageError(r, false);
                  }
                );
                break;
              case "CHAPTER":
                telecentro.tplay.core.biz.playerSource.getPlayerSource(
                  asset,
                  function (source) {
                    if (source.seekTime > 0) {
                      self.seekTime = source.seekTime;
                      self.setSource(source);
                    } else {
                      self.setSource(source);
                    }
                  },
                  (r) => {
                    self.manageError(r, false);
                  }
                );
                break;
              case "SERIE":
                telecentro.tplay.core.biz.series.getCapituloActivo(
                  asset,
                  function (rActivo) {
                    if (
                      rActivo.capituloActivo &&
                      rActivo.capituloActivo !== rActivo.primerCapitulo
                    ) {
                      rActivo.capituloActivo.seekTime *= 60;
                      rActivo.primerCapitulo.seekTime = 0;
                      telecentro.tplay.core.biz.playerSource.getPlayerSource(
                        source,
                        function (source) {
                          if (source.seekTime > 0) {
                            self.seekTime = ok ? source.seekTime : 0;
                            self.setSource(source);
                          } else {
                            self.setSource(source);
                          }
                        },
                        (r) => {
                          self.manageError(r, true);
                        }
                      );
                    } else {
                      telecentro.tplay.core.biz.playerSource.getPlayerSource(
                        rActivo.primerCapitulo,
                        self.setSource,
                        (r) => {
                          self.manageError(r, false);
                        }
                      );
                    }
                  }
                );
                break;
              default:
                break;
            }
            this.$bus.$emit("close-confirm-dialog");
          }
        }
        document.getElementById("msgCounter").innerText = actualTime;
        actualTime -= 1;
      }, 1000);
    },
    // Muestra un confirm al usuario preguntando si desea reproducir el siguiente capítulo de la serie que está mirando
    confirmPlayerNextEpisode(asset, onConfirm) {
      let title = asset.serie_name || asset.title;

      var msj =
        '<p class="h5">Temporada: ' +
        asset.n_temporada +
        " - Episodio: " +
        asset.n_episodio +
        "</p>";

      msj += "<p>" + title + "</p>";

      this.modalConfirmShow({
        type: "confirm",
        title: "¿Querés ver el próximo episodio?",
        acceptText: "Reproducir",
        cancelText: "Lo veré luego",
        content: msj,
        confirm: onConfirm,
      });
    },

    //Muestra un confirm al usuario preguntando si reproducir desde donde dejó la última vez.
    confirmPlayerSeekTime(originalType, asset, onConfirm) {
      //Chequeo si se cerró el Player antes de mostrar este mensaje
      //(mientras obtenía datos), y en ese caso no hago nada:
      if (this.state === "STOP" || this.state === "ERROR") {
        this.resetData();
        return;
      }
      this.decreaseNumber(asset);
      var textoNo = "Desde el Inicio";
      var msj = `<p>¿Querés reanudar desde donde estabas?</p> </br> <p style="margin-top: -20px;">este mensaje desaparecera en <span id="msgCounter">${this.timeToStart}</span> segundos </p>`;

      if (asset.type == "CHAPTER") {
        let donde = [];

        textoNo =
          originalType == "SERIE"
            ? "Desde el inicio de la serie"
            : "Desde el inicio del episodio";

        if (
          originalType == "SERIE" &&
          typeof asset.n_temporada != "undefined"
        ) {
          donde.push("Temporada " + asset.n_temporada);
        }

        if (originalType == "SERIE" && typeof asset.n_episodio != "undefined") {
          donde.push("Episodio " + asset.n_episodio);
        }

        if (asset.seekTime > 0) {
          donde.push(this.durationToLabel(asset.seekTime * 1000));
        }

        msj += "<p>(" + donde.join(", ") + ")</p>";
      } else {
        let donde = [];

        if (asset.seekTime > 0) {
          donde.push(this.durationToLabel(asset.seekTime * 1000));
        }

        msj += "<p>(" + donde.join(", ") + ")</p>";
      }

      this.modalConfirmShow({
        type: "confirm",
        title: "Reanudar reproducción",
        acceptText: "REANUDAR",
        cancelText: textoNo,
        content: msj,
        confirm: onConfirm,
      });
    },

    getControlProgress() {
      const p = this.$el.querySelector(".progress2");
      return {
        progress: p,
        progressLive: p.querySelector(".progress-bar-live"),
        fill: p.querySelector(".fill"),
        tooltip: p.querySelector(".tooltip"),
      };
    },

    //Duración del video, en milisegundos
    videoDuration() {
      if (this.isTvShow) {
        return (
          (this.asset.endHourUnixTime - this.asset.startHourUnixTime) * 1000
        );
      } else if (this.objProgress.seekRange && this.objProgress.seekRange[1]) {
        return this.objProgress.seekRange[1];
      }

      console.warn("seekRange vacío", this.objProgress.seekRange);
      return null;
    },

    //Traduce un progreso (representado como un nro entre 0 y 1) en un tiempo (en milisegundos)
    //que representa la proporción transcurrida de la duración del video a partir del comienzo.
    progressToTimePosition(progress) {
      //Sanitizo el rango para que siempre esté entre 0 y 1:
      progress = Math.max(0, progress);
      progress = Math.min(progress, 1);

      return progress * this.videoDuration();
    },

    //Mueve la posición de reproducción a una posición en milisegundos.
    seekByTime(time) {
      //Cuando estoy en vivo, la posición representa una hora (es decir, un desplazamiento respecto del 1/1/1970).
      //En asset.startHourUnixTime y asset.endHourUnixTime tengo los límites teóricos del video,
      //pero los límites reales disponibles son timeInfo.seekRange[0] y timeInfo.seekRange[1]
      //(de 2hs de duración desde el presente hacia atrás), obtenidos con window.vip.player.getInstance().getTimeInfo().

      //Cuando no estoy en vivo, la posición representa un desplazamiento desde el inicio del video, en milisegundos.

      this.resetLoader();

      if (this.isLive) {
        if (time === 0) {
          time = Infinity;
        }

        //si la pausa duró mucho tiempo, el seek no vuelve al vivo
        //entonces debo forzar ir hasta un punto actual sin pasarme del fin del programa
        if (
          time === Infinity &&
          this.asset.endHourUnixTime * 1000 > Date.now()
        ) {
          this.objProgress.seekRange[1] = Date.now();
        }

        var absolute = {
          position: Math.min(time, this.objProgress.seekRange[1]), //la posición no puede ser mayor que el rango de reproducción
          start: this.asset.startHourUnixTime * 1000,
          end: this.asset.endHourUnixTime * 1000,
        };

        var relative = {
          position: absolute.position - absolute.start,
          start: 0, //start - start
          end: absolute.end - absolute.start,
        };
        //console.log('position', absolute.position,  this.objProgress.seekRange[1]);
        window.vip.player.seekTo(absolute.position);
        if (this.isCcast) {
          this.$bus.$emit("cast-seekTo", absolute.position);
        }
        this.objProgress.fill.style.width =
          (100 * relative.position) / relative.end + "%";
      } else {
        // let duration = this.objProgress.seekRange[1];
        let duration = this.videoDuration();
        time = Math.min(time, duration); //La posición no puede ser mayor que la duración

        window.vip.player.seekTo(time);
        if (this.isCcast) {
          this.$bus.$emit("cast-seekTo", time);
        }
        this.objProgress.fill.style.width = (100 * time) / duration + "%";
      }

      this.sendMSG(_MSG_CODES.POSITION);
    },

    //Mueve la reproducción a una proporción de la reproducción (progress), expresada entre 0 y 1.
    //0 es el inicio de la reproducción, 0.5 es la mitad, 1 es el final, etc.
    seekByProgress(progress) {
      let start = this.isLive ? this.asset.startHourUnixTime * 1000 : 0;
      let time = start + this.progressToTimePosition(progress);

      this.resetLoader();

      //La posición que se pasa a seekTo puede ser dos cosas distintas:
      //* Si es un stream en vivo, representa un unixTime en milisegundos.
      //* Si es VOD, Catchup o NPVR, representa un desplazamiento en milisegundos desde el inicio del stream.
      window.vip.player.seekTo(time);
      if (this.isCcast) {
        this.$bus.$emit("cast-seekTo", time);
      }
      this.objProgress.fill.style.width = progress * 100 + "%";

      this.time.position = this.durationToLabel(time);

      if (time === 0 || progress === 0) {
        tplay.sendAudience(this.asset, "SOV");
      }

      this.sendMSG(_MSG_CODES.POSITION);
    },

    onControlSeek(e) {
      //console.log('onControlSeek')

      let maxSeekingPosition = this.objProgress.progress.offsetWidth;
      let userSeekingPosition =
        e.clientX - this.objProgress.progress.getBoundingClientRect().left;
      if (this.isCcast) {
        this.$bus.$emit(
          "cast-seekTo",
          this.progressToTimePosition(userSeekingPosition / maxSeekingPosition)
        );
      }
      this.seekByProgress(userSeekingPosition / maxSeekingPosition);
    },

    onProgressMouseMove: __.throttle(function (e) {
      const self = this;
      const op = self.objProgress;

      if (
        e.target !== op.progress &&
        e.target !== op.progressLive &&
        e.target !== op.fill
      ) {
        return;
      }

      requestAnimationFrame(function () {
        op.tooltip.style.left = e.offsetX + "px";
        let time = self.progressToTimePosition(
          e.offsetX / op.progress.offsetWidth
        );

        if (self.isTvShow) {
          //Cuando es TV, Catchup o NPVR, muestro la hora en el tooltip, relativa al
          //inicio del programa.
          self.time.tooltip = self.timeToLabel(
            self.asset.startHourUnixTime * 1000 + time
          );
        } else {
          self.time.tooltip = self.durationToLabel(time);
        }
      });
    }, 100),

    //video.currentTime es la posición de reproducción en segundos
    onControlForward(seconds = 30) {
      window.vip.player.getTimeInfo().then((timeInfo) => {
        this.seekByTime((seconds * 1000) + timeInfo.position);
      });
    },

    //video.currentTime es la posición de reproducción en segundos
    onControlBackward(seconds = -30) {
      window.vip.player.getTimeInfo().then((timeInfo) => {
        this.seekByTime((seconds * 1000) + timeInfo.position);
        tplay.sendAudience(this.asset, "REW");
      });
    },

    debounceShowLoader: __.debounce(function () {
      if (this.isPlaying()) {
        this.showLoader = true;
      }
    }, 1000),

    resetLoader() {
      this.showLoader = false;
      this.debounceShowLoader();
    },

    updateProgress: __.throttle(function () {
      let self = this;

      this.resetLoader();

      window.vip.player.getTimeInfo().then((timePosition) => {
        this.time.timeInfo = timePosition;

        // console.log('getTimeInfo',timePosition);
        //Si llegué hasta acá, es que el setSource fue exitoso
        if (self.setSourceTimer) {
          clearTimeout(self.setSourceTimer);
          self.setSourceTimer = 0;
        }
        if (
          self.isCcast &&
          document.getElementsByClassName("veygoSubsDisplayer").length > 0
        ) {
          document
            .getElementsByClassName("veygoSubsDisplayer")[0]
            .classList.remove("showSubtitles");
          document
            .getElementsByClassName("veygoSubsDisplayer")[0]
            .classList.add("hideSubtitles");
        }
        if (
          !self.isCcast &&
          document.getElementsByClassName("veygoSubsDisplayer").length > 0
        ) {
          document
            .getElementsByClassName("veygoSubsDisplayer")[0]
            .classList.remove("hideSubtitles");
          document
            .getElementsByClassName("veygoSubsDisplayer")[0]
            .classList.add("showSubtitles");
        }

        if (timePosition === null) return;

        if (timePosition.position <= 0 || !timePosition.seekRange) return;

        self.isLive = timePosition.isLive;

        self.objProgress.seekRange = timePosition.seekRange;

        if (timePosition.isLive) {
          const toleranciaAtraso = 30000; //30 segundos, expresados en ms
          //Si estoy a 30 segundos o menos atrás del presente, considero que estoy en el presente.

          const seekEnd = timePosition.seekRange[1]; //Milisegundos
          const pos = timePosition.position / 1000; //Segundos

          //TODO: cuando termino de reproducir el programa, debería poder pedir al CORE el próximo programa, en vez de escuchar el evento de cambio de programación
          if (self.proximoAsset && pos > self.asset.endHourUnixTime) {
            //Renuevo el asset, así hago los cálculos en base al mismo y muestro la info nueva
            self.asset = self.proximoAsset;
            self.playerSource = self.proximoAsset;
            self.proximoAsset = null;
          }

          if (timePosition === null) return;

          if (timePosition.position <= 0 || !timePosition.seekRange) return;

          self.isLive = timePosition.isLive;

          self.objProgress.seekRange = timePosition.seekRange;

          // if (timePosition.isLive) {

          // 	const toleranciaAtraso = 30000; //30 segundos, expresados en ms
          // 	//Si estoy a 30 segundos o menos atrás del presente, considero que estoy en el presente.

          // 	const seekEnd = timePosition.seekRange[1]; //Milisegundos
          // 	const pos = timePosition.position / 1000; //Segundos

          // 	//TODO: cuando termino de reproducir el programa, debería poder pedir al CORE el próximo programa, en vez de escuchar el evento de cambio de programación
          // 	if (self.proximoAsset && pos > self.asset.endHourUnixTime) {
          // 		//Renuevo el asset, así hago los cálculos en base al mismo y muestro la info nueva
          // 		self.asset = self.proximoAsset;
          // 		self.playerSource = self.proximoAsset;
          // 		self.proximoAsset = null;
          // 	}

          const duracion =
            self.asset.endHourUnixTime - self.asset.startHourUnixTime; //Segundos
          const transcurridoLive =
            seekEnd / 1000 - self.asset.startHourUnixTime; //Segundos
          const transcurrido =
            timePosition.position / 1000 - self.asset.startHourUnixTime; //Segundos
          const porcentajeTranscurrido = Math.min(
            (transcurrido / duracion) * 100,
            100
          ); //Lo limito a 100 como máximo
          const porcentajeTranscurridoLive = Math.min(
            (transcurridoLive / duracion) * 100,
            100
          ); //Lo limito a 100 como máximo
          const isSeek = seekEnd - timePosition.position > toleranciaAtraso;

          self.objProgress.progressLive.style.width =
            porcentajeTranscurridoLive + "%";
          self.isLiveStream = isSeek;

          if (isSeek) {
            self.objProgress.fill.style.width = porcentajeTranscurrido + "%";
          } else {
            self.objProgress.fill.style.width =
              porcentajeTranscurridoLive + "%";
          }

          // 	self.time.position = self.timeToLabel(timePosition.position);
          // 	self.time.duration = self.timeToLabel(self.asset.endHourUnixTime * 1000);

          // } else if (self.isTvShow) { //catchup o NPVR

          // 	// const duracion = timePosition.seekRange[1] - timePosition.seekRange[0]; //ms
          // 	// const transcurrido = timePosition.position - timePosition.seekRange[0]; //ms
          // 	// const porcentajeTranscurrido = transcurrido * 100 / duracion;
          // 	const porcentajeTranscurrido = timePosition.position * 100 / self.videoDuration();

          // 	self.objProgress.fill.style.width = porcentajeTranscurrido + '%';

          // 	self.time.position = self.timeToLabel(self.asset.startHourUnixTime * 1000 + timePosition.position);
          // 	self.time.duration = self.timeToLabel(self.asset.endHourUnixTime * 1000);

          // } else {
          // 	const duracion = timePosition.seekRange[1] - timePosition.seekRange[0]; //ms
          // 	const transcurrido = timePosition.position - timePosition.seekRange[0]; //ms
          // 	const porcentajeTranscurrido = transcurrido * 100 / duracion;

          // 	self.objProgress.fill.style.width = porcentajeTranscurrido + '%';

          // 	self.time.position = self.durationToLabel(timePosition.position);
          // 	self.time.duration = self.durationToLabel(timePosition.seekRange[1]);
          // }

          self.time.position = self.timeToLabel(timePosition.position);
          self.time.duration = self.timeToLabel(
            self.asset.endHourUnixTime * 1000
          );
          if (self.isCcast)
            self.$bus.$emit("cast-setposition", self.time.position);
        } else if (self.isTvShow) {
          //catchup o NPVR

          const porcentajeTranscurrido =
            (timePosition.position * 100) / self.videoDuration();

          self.objProgress.fill.style.width = porcentajeTranscurrido + "%";

          self.time.position = self.timeToLabel(
            self.asset.startHourUnixTime * 1000 + timePosition.position
          );
          self.time.duration = self.timeToLabel(
            self.asset.endHourUnixTime * 1000
          );
          if (self.isCcast)
            self.$bus.$emit("cast-setposition", self.time.position);
        } else {
          const duracion =
            timePosition.seekRange[1] - timePosition.seekRange[0]; //ms
          const transcurrido =
            timePosition.position - timePosition.seekRange[0]; //ms
          const porcentajeTranscurrido = (transcurrido * 100) / duracion;
          self.objProgress.fill.style.width = porcentajeTranscurrido + "%";

          self.time.position = self.durationToLabel(timePosition.position);
          self.time.duration = self.durationToLabel(timePosition.seekRange[1]);
          if (self.isCcast)
            self.$bus.$emit(
              "cast-setposition",
              self.time.position,
              timePosition.position
            );
        }

        var sourceStoraged = localStorage.getItem("mediaSource");
        if (sourceStoraged) {
          sourceStoraged = JSON.parse(sourceStoraged);
          sourceStoraged.seekTime = self.seekTime;
          localStorage.setItem("mediaSource", JSON.stringify(sourceStoraged));
        }
        // console.log( 'el seekTime web es: ', self.seekTime);
      });
    }, 500),

    onPlayerReady() {
      let self = this;

      self.objProgress.seekRange = null;

      if (self.seekTime > 0) {
        // self.onControlPause();
        // self.video.currentTime = self.seekTime;
      }

      self.state = "LOAD"; //Esto tiene que estar antes de onControlPlay()
      self.sendMSG(_MSG_CODES.LOAD);
      self.completedSend = false;

      let ParentalControlAge =
        telecentro.tplay.core.biz.parentalControl.getAge();

      // valor seteado de tiempo en control parental
      let parentalControlSetTime = self.conversionMinutesToSecondsCP();

      // cronometro para el control parental
      if (!self.timerTimeParentalControl) {
        let startTimeParentalControl = Date.now() - self.runingTime;
        self.timerTimeParentalControl = setInterval(() => {
          self.runingTime = Date.now() - startTimeParentalControl;
          let secondsTime = Math.floor(self.runingTime / 1000);
          if (
            parentalControlSetTime === secondsTime &&
            parentalControlSetTime !== 0 &&
            parentalControlSetTime !== -60 &&
            ParentalControlAge !== -1
          ) {
            self.onControlPlayPause();
            self.hiddePlayerOnPin = true;
            window.removeEventListener("keyup", self.keyboardControls, false);
            self.$bus.$emit(
              "modal-pin:show",
              () => {
                self.hiddePlayerOnPin = false;
                window.addEventListener("keyup", self.keyboardControls, false);
                startTimeParentalControl = Date.now();
                self.onControlPlayPause();
                setTimeout(() => {
                  self.tplayPlayer.show();
                  self.tplayPlayer.showControls();
                  if (self.isLive) {
                    self.seekByTime(Infinity);
                  }
                }, 0);
              },
              () => {
                document.getElementById("btn-exit").click();
              }
            );
          }
          return secondsTime;
        }, 1000);
      } else {
        window.clearInterval(self.timerTimeParentalControl);
        self.timerTimeParentalControl = null;
      }

      self.onControlPlay();

      window.vip.player
        .getDrmProperty()
        .then(function (value) {
          console.log("verimatrix.id is " + value);
        })
        .catch((error) => {
          console.error("[ PLAYER ] --> getDrmProperty fail", error);
        });

      self.video.style.opacity = 1;

      self.initTracks();

      //Muestro los botones de modo (pip, fullscreen)
      self.tplayPlayer.showModeButtons();
      if (this.isCcast) {
        //this.video.muted = true;
        window.vip.player.setVolume({muted: true});
      }
    },

    onPlayerStatus(info) {
      const self = this;

      //console.log('Vip Player status:', info.status, info);
      // self.playerStatus = info;

      if (info.status === "initialized") {
        return;
      }

      if (info.status === "ready") {
        this.onPlayerReady();
        return;
      }

      if (info.status === "playing") {
        this.onErrorIntent = 0;
      }

      if (info.status === "error") {

        console.log('error vip')

        if(this.hiddePlayerOnPin) {
          return;
        }

        this.state = "ERROR";
        // self.sendMSG('PLAYBACKERROR');

        if (gtag) {
          //Hago un serializado seguro, evitando agregar objetos más
          //de una vez (así evito referencias circulares).
          var cache = [];

          var streamInfo = JSON.stringify(this.stream, function (key, value) {
            //No serializo el asset completo
            if (key === "asset") {
              return {
                idAsset: value.idAsset ? value.idAsset : "undefined",
                type: value.type ? value.type : "undefined",
              };
            }

            if (value !== null && typeof value === "object") {
              if (cache.indexOf(value) !== -1) {
                //Ya serialicé este valor, así que lo descarto esta vez
                return;
              }
              //Es un valor nuevo. Lo agrego a cache para no repetirlo
              cache.push(value);
            }

            return value;
          });

          gtag("event", "Player error", {
            event_category: "Player",
            event_action: "Player error 2",
            event_label: "[ cg:" + info.cg + " ] " + streamInfo,
            value: "0",
          });
        }

        //console.log('player error: ', info);

        this.manageError("", true);

        return;
      }

      if (info.status === "buffering") {
        return;
      }

      if (info.status === "ended") {
        this.sendMSG(_MSG_CODES.COMPLETED);
        this.closeByConcurrency();
        return;

        if (this.asset.type == "SERIE" || this.asset.type == "CHAPTER") {
          // Si el asset es una serie

          let self = this;

          // Corroboro si no es el último episodio de la serie
          telecentro.tplay.core.biz.series.siguienteEpisodio(
            this.playerSource,
            function (nextAsset) {
              if (nextAsset) {
                // Si hay un siguiente capítulo
                // Muestro modal para reproducir el siguiente capítulo.
                self.confirmPlayerNextEpisode(nextAsset, function (isOK) {
                  if (isOK) {
                    self.$bus.$emit("player:set-source", nextAsset);
                  }
                });
              }
            }
          );
        } else {
          this.state = "END";
          this.titleBtn = "Volver a reproducir";
          this.btnPlayState = "PLAY";
          return;
        }
      }
    },

    onPlayerClick() {
      if (!this.isReady) return;

      if (this.rail.visible) {
        //Oculto el rail de sugeridos
        this.loadSuggested();
        this.onControlPlayPause();
      } else {
        //play / pause
        this.onControlPlayPause();
      }
    },

    onControlPlayPause() {
      if (!this.isReady) return;
      if (this.isCcast) {
        this.$bus.$emit("cast-playpause");
      }
      if (this.btnPlayState == "PLAY") {
        this.onControlPlay(false, false);
      } else {
        this.onControlPause();
      }
    },

    onPlayEvent() {
      //this.onControlPlay(true);
      //this.btnPlayState = 'PAUSE';
    },

    onPauseEvent() {
      //this.onControlPause(true);
    },

    onControlPlay(avoidAction = false, trackInAction = true) {
      if (!this.isReady) {
        return;
      }

      //this.sendMSG('START');
      this.titleBtn = "Pausa";
      this.btnPlayState = "PAUSE";

      this.resetLoader();

      if (!avoidAction) {
        this.state = "START";
        window.vip.player.play();
      }
      if (trackInAction) {
        this.sendMSG(_MSG_CODES.START);
      } else {
        tplay.sendAudience(this.asset);
        this.sendMSG(_MSG_CODES.RESUME);
      }
    },

    onControlPause(avoidAction = false, trackInAction = true) {
      if (!this.isReady) {
        return;
      }

      this.state = "PAUSE";
      // this.sendMSG('PAUSE');
      this.titleBtn = "Reproducir";
      this.video.style.opacity = null;

      if (!avoidAction) {
        window.vip.player.pause();
      }

      if (trackInAction) {
        this.sendMSG(_MSG_CODES.PAUSE);
        tplay.sendAudience(this.asset, "PAU");
      }

      this.video.style.opacity = 1;

      this.btnPlayState = "PLAY";
    },

    initTracks() {
      // Trae los subtitulos e idiomas disponibles
      const self = this;
      const defaultName = "es";

      self.tracks = window.vip.player.getTracks();

      self.lastSelectedAudioTrackName =
        telecentro.tplay.core.lang.getLastAudio();
      self.lastSelectedTextTrackName =
        telecentro.tplay.core.lang.getLastSubtitles();

      if (self.tracks) {
        let defaultAudio = null;

        if (self.tracks.audio) {
          let lastAudio = null;

          //Busco si existe el último track, seleccionado en el video anterior,
          //o en su defecto si existe el default.
          if (self.lastSelectedAudioTrackName) {
            lastAudio = __.find(
              self.tracks.audio,
              (t) => t.name === self.lastSelectedAudioTrackName
            );
          }

          if (lastAudio) {
            self.setTrack(lastAudio, self.tracks.audio);
          } else {
            defaultAudio = __.find(
              self.tracks.audio,
              (t) => t.name === defaultName
            );
            if (defaultAudio) {
              self.setTrack(defaultAudio, self.tracks.audio);
            }
          }
        }

        if (self.tracks.text) {
          let lastText = null;

          //Busco si existe el último track, seleccionado en el video anterior.
          if (self.lastSelectedTextTrackName) {
            lastText = __.find(
              self.tracks.text,
              (t) => t.name === self.lastSelectedTextTrackName
            );
          }

          if (lastText) {
            self.setTrack(lastText, self.tracks.text);
          } else if (
            self.lastSelectedTextTrackName &&
            !defaultAudio &&
            self.lastSelectedTextTrackName != defaultName
          ) {
            //Si no existe el texto seleccionado en el video anterior, y el audio no
            //es el default, y el texto seleccionado en el video anterior no es el
            //default, intento usar el texto default.
            let defaultText = __.find(
              self.tracks.text,
              (t) => t.name === defaultName
            );
            if (defaultText) {
              self.setTrack(defaultText, self.tracks.text);
            }
          }
        }

        self.applyTracks();
      }
    },

    setTrack(track, trackList) {
      //Desactivo todos
      for (let t of trackList) {
        window.vip.player.toggleTrack(t, false);
      }

      //Activo el que se seleccionó
      if (track) {
        window.vip.player.toggleTrack(track, true);
      }
    },

    applyTracks() {
      const self = this;
      window.vip.player
        .applyTracks()
        .then(() => {
          self.tracks = window.vip.player.getTracks();
        })
        .catch((error) => {
          console.error("[ PLAYER ] --> applyTracks error", error);
        });
    },

    onSetAudioTrack(track) {
      if (this.isCcast) {
        this.$bus.$emit("cast-settrack", track, "SET_AUDIO");
      }
      this.setTrack(track, this.tracks.audio);
      this.applyTracks();
      this.lastSelectedAudioTrackName = track ? track.name : null;
      telecentro.tplay.core.lang.setLastAudio(this.lastSelectedAudioTrackName);
    },
    onSetTextTrack(track) {
      if (this.isCcast) {
        this.$bus.$emit("cast-settrack", track, "SET_SUBTITLE");
      }
      this.setTrack(track, this.tracks.text);
      this.applyTracks();
      this.lastSelectedTextTrackName = track ? track.name : null;
      telecentro.tplay.core.lang.setLastSubtitles(
        this.lastSelectedTextTrackName
      );
    },
    onControlMute() {
      if (this.isCcast) {
        this.$bus.$emit("cast-muteorunmuted");
      } else {

        const volumeInfo = window.vip.player.getVolume();
        
        window.vip.player.setVolume({muted: !volumeInfo.muted});

        //this.video.muted = !this.video.muted;
      }

      this.checkMuted = !this.checkMuted;

    },

    onControlAlterVolume() {
      let range = document.querySelector('[name="volumen"]').value;
      if(!isNaN(range)){
        range = parseFloat(range);
        window.vip.player.setVolume({volume: range});
        //this.checkMuted = false;
        //this.video.volume = Math.sin(((range.value / 100) * Math.PI) / 2);
        if (this.isCcast) {
          this.$bus.$emit("cast-setvolume", range);
        }
      }
    },

    onDeleteRecord() {
      const self = this;

      this.modalConfirmShow({
        type: "confirm",
        classType: "danger",
        title: "Confirmá esta acción",
        acceptText: "Sí, Eliminar",
        cancelText: "No",
        content: "<p>¿Seguro quieres eliminar esta grabación?</p>",

        confirm: function (ok) {
          if (ok) {
            const stopRecordingCallback = tplay.stopRecordingCallback(
              self.asset,
              self.recordOptions,
              self.$bus
            );

            if (self.playerSource.type == "RECORD") {
              telecentro.tplay.core.npvr.borrarGrabacion(
                self.playerSource.idRecord,
                stopRecordingCallback
              );
            } else {
              telecentro.tplay.core.npvr.borrarGrabacionPorIdEvent(
                self.asset.idAsset,
                stopRecordingCallback
              );
            }
          }
        },
      });
    },

    onBtnRecord() {
      this.recordOptions.show = !this.recordOptions.show;
    },

    onControlSaveRecord() {
      tplay.startRecording(this.asset, this.recordOptions, this.$bus);
      this.recordOptions.show = false;
    },

    onControlClose() {

      this.hiddePlayerOnPin = false;

      bcSc.close();

      this.state = "STOP";
      this.sendMSG(_MSG_CODES.STOP);

      tplay.sendAudience(this.asset, "STP");

      window.vip.player.freeze();
      window.vip.player.playing = false;

      window.removeEventListener("keyup", self.keyboardControls, false);

      this.$bus.$emit("player:close");
      // console.log('VOLVER');
      this.resetData();
    },

    concurrencyConfirmModal() {
      this.modalConfirmShow({
        type: "info",
        title: "Llegaste al límite de dispositivos",

        content: `<p>
				  Tenés contratado el pack, pero alcanzaste el límite de dispositivos.<br>
				  Para gestionar el acceso al contenido entrá a <a href="https://telecentro.com.ar/packs-adicionales" title="Comprar Pack en Sucursal Virtual" target="_blank" rel="noopener">Sucursal Virtual</a>
				  <i class="zmdi zmdi-open-in-new"></i>.
				</p>`,

        confirm: function (ok) {},
      });
    },

    closeByConcurrency() {
      const self = this;
      self.state = "STOP";
      window.vip.player.freeze();
      window.removeEventListener("keyup", self.keyboardControls, false);
      self.$bus.$emit("player:close");
      self.resetData();
      self.tplayPlayer.hide();
    },

    duplicatedPlaybackConfirmModal() {
      const self = this;
      const asset = Object.assign({}, self.asset);
      const msj = `<p>
							Hemos detectado que iniciaste una reproducción en otra pestaña de este navegador.
						</p>`;

      this.modalConfirmShow({
        type: "confirm",
        title: "Reproducción en simultáneo",
        acceptText: "Continuar aquí",
        cancelText: "Cerrar",
        content: msj,
        confirm: function (ok) {
          if (ok) {
            self.$bus.$emit("player:set-source", asset);
          }
        },
      });
    },

    closeByDuplicatedPlayback() {
      const self = this;
      self.state = "STOP";
      window.vip.player.freeze();
      window.removeEventListener("keyup", self.keyboardControls, false);
      self.$bus.$emit("player:close");
      self.resetData();
      self.tplayPlayer.hide();
    },

    timeFormat() {
      if (typeof Intl != "undefined") {
        return new Intl.NumberFormat("en-EN", {
          minimumIntegerDigits: 2,
          maximumFractionDigits: 0,
        });
      } else {
        return {
          format: function (value) {
            var x = "" + Math.floor(value);
            while (x.length < 2) {
              x = "0" + x;
            }
            return x;
          },
        };
      }
    },

    pad(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    },

    //ms: cantidad de milisegundos, una duración
    durationToLabel(ms) {
      let minutes = this.numberTimeFormat.format(
        parseInt((ms / (1000 * 60)) % 60)
      );
      let seconds = this.numberTimeFormat.format(parseInt((ms / 1000) % 60));
      let hours = this.numberTimeFormat.format(
        parseInt((ms / (1000 * 60 * 60)) % 24)
      );
      if (hours >= 1) {
        return hours + ":" + minutes + ":" + seconds;
      } else {
        return minutes + ":" + seconds;
      }
    },

    //ms cantidad de milisegundos desde 1970-01-01 00:00:00 UTC
    timeToLabel(ms) {
      //let d = new Date(1970, 0, 1, 0, 0, 0, ms);
      let d = new Date(ms);
      let hours = d.getHours();
      let minutes = this.pad(d.getMinutes(), 2);
      let seconds = this.pad(d.getSeconds(), 2);

      if (hours >= 1) {
        return hours + ":" + minutes + ":" + seconds;
      } else {
        return minutes + ":" + seconds;
      }
    },

    timeInSeconds(ms) {
      let d = new Date(ms);
      let hours = d.getHours();
      let minutes = this.pad(d.getMinutes(), 2);
      let seconds = this.pad(d.getSeconds(), 2);

      return hours * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds);
    },

    //Determina si el player está abierto
    isActive: function () {
      return this.state != "STOP";
    },
    //Determina si se está reproduciendo o no (o si está inicializado preparando la reproducción)
    isPlaying: function () {
      return (
        this.state == "LOAD" || this.state == "START" || this.state == "INIT"
      );
    },
    //Determina si el asset actual es el indicado por el parámetro idAsset o no.
    //Chequea si idAsset es igual a this.asset.idAsset o a this.asset.serie.idAsset.
    isAsset: function (idAsset) {
      return !!(
        this.state &&
        this.asset &&
        ((this.asset.idAsset && this.asset.idAsset == idAsset) ||
          (this.asset.serie &&
            this.asset.serie.idAsset &&
            this.asset.serie.idAsset == idAsset))
      );
    },

    onCambioProgramacion(lcn, programacionTV) {
      if (
        this.asset &&
        this.asset.channel &&
        programacionTV &&
        programacionTV.channel &&
        programacionTV.channel.id &&
        programacionTV.idAsset &&
        programacionTV.channel.id === this.asset.channel.id &&
        programacionTV.idAsset !== this.asset.idAsset
      ) {
        this.proximoAsset = programacionTV;
      }
    },

    onPlayerDoubleClick() {
      //Toggle de fullscreen
      document.querySelector(".player .btn-fullscreen .zmdi").click();
    },

    getEpisodios(season, onFinish) {
      const self = this;
      const p = telecentro.tplay.core.helpers.pipe(getSerie);

      p.pipe(function (next, assetSerie) {
        if (!assetSerie) {
          onFinish([]);
          return;
        }

        if (!assetSerie.seasons) {
          console.error("La serie no tiene temporadas.", assetSerie);
          onFinish([]);
          return;
        }

        season = season || getTemporadaActual(assetSerie, self.asset);

        if (season.capitulos) {
          next(assetSerie, season);
        } else {
          telecentro.tplay.core.series.capitulosDeTemporada(
            season.idCatalog,
            function (episodios) {
              season.capitulos = episodios;
              next(assetSerie, season);
            }
          );
        }
      });

      p.pipe(function (next, assetSerie, season) {
        self.asset.serie = assetSerie;
        self.rail.selectedSeason = season;

        //TODO: esto debería resolverse en el CORE?
        //Recorro los episodios obtenidos y les pongo el objeto serie correspondiente,
        //así cuando los seleccione puedo recuperar la serie.
        for (let i = 0; i < season.capitulos.length; i++) {
          season.capitulos[i].serie = assetSerie;
        }

        //Seteo el seekTime de los episodios (en paralelo, para no demorar la carga)
        telecentro.tplay.core.vod.refreshSeekTime(season.capitulos);

        onFinish(season.capitulos);
      });

      p.run();

      //Determino el id de la temporada que estoy reproduciendo
      function getTemporadaActual(assetSerie, asset) {
        //Puede ser que el idSeason no sea el correcto, así que necesito chequear que la
        //temporada exista en el asset de la serie.
        //Puede ser que el idSeason del episodio no sea  igual al de la temporada, pero aún
        //así la temporada sea la misma.
        //TODO: los datos deberían ser consistentes.

        //Busco la temporada en la serie, por id de la temporada:
        let season = assetSerie.seasons.find(
          (s) => s.idCatalog == asset.idSeason
        );
        if (season) return season;
        console.warn(
          "Temporada no encontrada en la serie (por id de temporada)."
        );

        //Busco la temporada en la serie, por id del episodio:
        season = assetSerie.seasons.find(
          (s) =>
            s.capitulos && s.capitulos.find((e) => e.idAsset == asset.idAsset)
        );
        if (season) return season;
        console.warn(
          "Temporada no encontrada en la serie (por id de episodio)."
        );

        //Busco la temporada en la serie, por nro de temporada:
        season = assetSerie.seasons[parseInt(asset.n_temporada) - 1];
        if (season) return season;
        console.warn(
          "Temporada no encontrada en la serie (por nro de temporada)."
        );

        //No me queda otra que retornar la primera temporada:
        return assetSerie.seasons[0];
      }

      //Determino u obtengo el asset de la serie:
      function getSerie(next) {
        if (self.asset.serie) {
          next(self.asset.serie);
        } else if (self.playerSource.type === "SERIE") {
          next(self.playerSource);
        } else {
          const idAsset =
            self.asset.type === "CHAPTER"
              ? self.asset.idSerie
              : self.asset.idAsset;
          telecentro.tplay.core.series.datosDeSerie(
            idAsset,
            next,
            function (err) {
              console.error("ERROR", err);
            }
          );
        }
      }
    },

    selectSeason(season) {
      const self = this;
      this.getEpisodios(season, function (episodios) {
        self.rail.list = episodios;
      });
    },

    slideSuggested(show, onFinish) {
      const btn = this.$el.querySelector(".player-bottom-trigger");
      const bottom = this.$el.querySelector(".player-controls-bottom");
      const rail = this.$el.querySelector(".suggested-content .poster-rail");

      if (rail) {
        rail.style.display = "none";
      }

      // TODO check if button is not present
      if (btn) {
        btn.classList.remove("clicked");
        btn.classList.add("clicked");
      }

      if (show) {
        this.$el.classList.add("has-suggested-content");
        bottom.classList.add("slide-up");
        bottom.classList.remove("slide-down");
        this.rail.visible = true;
        tplay.Player.suggestedContentVisible = true;
      } else {
        this.$el.classList.remove("has-suggested-content");
        bottom.classList.remove("slide-up");
        bottom.classList.add("slide-down");

        this.rail.visible = false;
        this.rail.animating = false;
        tplay.Player.suggestedContentVisible = false;
      }

      tplay.waitTransition(bottom, function (e) {
        bottom.classList.remove("transition-done");
        bottom.classList.add("transition-done");

        if (rail) {
          rail.style.display = "";
        }

        if (typeof onFinish === "function") {
          onFinish();
        }
      });
    },

    loadSuggested() {
      if (this.rail.animating) {
        return;
      }
      this.zapFlagParentalControl = !this.zapFlagParentalControl;

      this.rail.animating = true;

      const self = this;

      self.rail.visible = !self.rail.visible;

      let end = function () {
        self.rail.animating = false;
      };

      if (!self.rail.list && self.rail.visible) {
        const slideSuggested = function (done) {
          self.slideSuggested(self.rail.visible, function () {
            end();
            done();
          });
        };

        const getSuggested = function (done) {
          if (self.rail.list) {
            //Ya vienen indicados externamente los sugeridos
            return self.rail.list;
          } else if (self.asset.type === "MOVIE") {
            telecentro.tplay.core.recomendaciones.peliculas(
              self.asset,
              20,
              function (recomendadas) {
                recomendadas.unshift(self.asset); //Agrego el asset actual al inicio
                done(recomendadas);
              }
            );
          } else if (
            self.asset.type === "SERIE" ||
            self.asset.type === "CHAPTER"
          ) {
            self.getEpisodios(null, done);
          } else if (
            self.asset.type === "RECORD" ||
            self.playerSource.type === "RECORD"
          ) {
            telecentro.tplay.core.recomendaciones.grabaciones(
              self.asset,
              20,
              done
            );
          } else if (self.isTvShow) {
            var ahora = Date.now() / 1000;
            var fechaDesde = ahora - 60 * 60 * 24; //24 hs para atrás
            var fechaHasta = ahora + 60 * 60 * 6; //6 hs para adelante
            telecentro.tplay.core.epg.obtenerProgramacion(
              fechaDesde,
              fechaHasta,
              self.asset.channel.id,
              done
            );
          }
        };

        telecentro.tplay.core.helpers.join(
          getSuggested,
          slideSuggested
        )(function (list) {
          self.rail.list = list;
        });
      } else {
        self.slideSuggested(self.rail.visible, end);
      }
    },

    onElementClick(e) {
      //Con esto evito que los click que se hacen sobre el player sigan propagándose,
      //así no se cierran los modales por ejemplo
      e.stopPropagation();
    },

    updatePlayerViewOnCasting(dataStatus) {},

    getPoster() {
      if (this.asset.channel) {
        return this.asset.poster ? this.asset.poster : this.asset.channel.logo;
      } else {
        return null;
      }
    },

    startTimer() {
      const self = this;
      return new Promise((resolve) => {
        clearInterval(self.timerInterval);
        self.timerInterval = window.setInterval(self.updateProgress, 1000);
        resolve(true);
      });
    },
  },

  watch: {
    state: {
      sync: true, //Importante! Sin esto, en el 'STOP' ya no tengo el idAsset
      handler(newState) {
        // console.log('player:state-change', oldState, newState);

        try{
          window.vip.player.getTimeInfo().then((timeInfo) => {
            let position = 0;
            if(timeInfo){
              position = timeInfo.position;
            }
            this.$bus.$emit("player:state-change", newState, {
              idAsset: this.asset.idAsset,
              seekTime: position
            });
          });
        }catch(e){
          this.$bus.$emit("player:state-change", newState, {
              idAsset: this.asset.idAsset,
              seekTime: 0
          });
        }
         
        
      },
    },
  },

  beforeDestroy() {
    this.$bus.$off("cast-setSourcePlayer", () => this.setSourceCast());
    this.$bus.$off("close-player", () => {
      document.getElementById("btn-exit").click();
    });
    telecentro.tplay.core.epg.eventoCambioProgramacion.off(
      this.onCambioProgramacion
    );

    // self.bcSc.close();

    window.removeEventListener("keyup", this.keyboardControls, false);
    this.$el.removeEventListener("click", this.onElementClick);

    const op = this.getControlProgress();
    if (op && op.progress) {
      op.progress.removeEventListener("mousemove", this.onProgressMouseMove);
    }

    if (this.timerPosition) {
      window.clearInterval(this.timerPosition);
    }

    if (this.timerTimeParentalControl) {
      window.clearInterval(this.timerTimeParentalControl);
      this.timerTimeParentalControl = null;
    }

    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    window.vip.player.removeEventListener(
      "status",
      self.onPlayerStatus.bind(self),
      false
    );
  },
};
</script>


