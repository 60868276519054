
const _LINEAR_PARAMS = { 
	frequency: null, // No aplica para nosotros solo para DVB
	technology: null, 
	drm: null,
	reason: null,
	livePosition: null,
	bufferStartPosition: null, 
	playbackPosition: null,
	position: null,
	transform: null,
	origin: null,
	audioLanguage: null,
	subtitleLanguage: null,
	audioCodec: null,
	videoCodec: null,
	throughNanoCdn: null,
	dueToBufferUnderflow: null
};


const _VOD_PARAMS = {
	from: null,
	position: null,
	timeSpent: null,
	transform: null,
	unit: 'seconds',
	bitrate: null,
	width: null,
	height: null,
};

const _NPVR_PARAMS = {
	from: null,
	position: null,
	transform: null,
};

export {
	_LINEAR_PARAMS,
	_VOD_PARAMS,
	_NPVR_PARAMS,
};
