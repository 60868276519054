const _MSG_LINEAR = {

	LINEAR_LOAD: 'LINEAR_LOAD',

	LINEAR_START: 'LINEAR_START',

	LINEAR_ENABLE_TIMESHIFT: 'LINEAR_ENABLE_TIMESHIFT',

	LINEAR_POSITION: 'LINEAR_POSITION',

	LINEAR_STOP: 'LINEAR_STOP',

	LINEAR_RESUME: 'LINEAR_RESUME',

	LINEAR_INITIAL_LOAD_ERROR: 'LINEAR_INITIAL_LOAD_ERROR',

	LINEAR_PLAYBACK_NON_FATAL_ERROR: 'LINEAR_PLAYBACK_NON_FATAL_ERROR',

	LINEAR_PLAYBACK_FATAL_ERROR: 'LINEAR_PLAYBACK_FATAL_ERROR',

	LINEAR_STOP_ON_CONTEXT_CHANGE: 'LINEAR_STOP_ON_CONTEXT_CHANGE',

	LINEAR_DISPLAY_MODE_CHANGE: 'LINEAR_DISPLAY_MODE_CHANGE',

	LINEAR_STREAM_CHANGE: 'LINEAR_STREAM_CHANGE',

};

const _MSG_VOD = {

	VOD_LOAD: 'VOD_LOAD',

	VOD_START: 'VOD_START',

	VOD_POSITION: 'VOD_POSITION',

	VOD_COMPLETE: 'VOD_COMPLETE',

	VOD_PAUSE: 'VOD_PAUSE',

	VOD_STOP: 'VOD_STOP',

	VOD_RESUME: 'VOD_RESUME',

	VOD_INITIAL_LOAD_ERROR: 'VOD_INITIAL_LOAD_ERROR',

	VOD_PLAYBACK_NON_FATAL_ERROR: 'VOD_PLAYBACK_NON_FATAL_ERROR',

	VOD_PLAYBACK_FATAL_ERROR: 'VOD_PLAYBACK_FATAL_ERROR',

	VOD_STOP_ON_CONTEXT_CHANGE: 'VOD_STOP_ON_CONTEXT_CHANGE',

	VOD_DISPLAY_MODE_CHANGE: 'VOD_DISPLAY_MODE_CHANGE',

};

const _MSG_NPVR = {

	NPVR_LOAD: 'NPVR_LOAD',

	NPVR_START: 'NPVR_START',

	NPVR_POSITION: 'NPVR_POSITION',

	NPVR_COMPLETE: 'NPVR_COMPLETE',

	NPVR_PAUSE: 'NPVR_PAUSE',

	NPVR_STOP: 'NPVR_STOP',

	NPVR_RESUME: 'NPVR_RESUME',

	NPVR_INITIAL_LOAD_ERROR: 'NPVR_INITIAL_LOAD_ERROR',

	NPVR_PLAYBACK_NON_FATAL_ERROR: 'NPVR_PLAYBACK_NON_FATAL_ERROR',

	NPVR_PLAYBACK_FATAL_ERROR: 'NPVR_PLAYBACK_FATAL_ERROR',

	NPVR_STOP_ON_CONTEXT_CHANGE: 'NPVR_STOP_ON_CONTEXT_CHANGE',

	NPVR_DISPLAY_MODE_CHANGE: 'NPVR_DISPLAY_MODE_CHANGE',

};

export {
	_MSG_LINEAR,
	_MSG_VOD,
	_MSG_NPVR
};